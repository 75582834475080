.InvalidTokenError .Buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.InvalidTokenError .Buttons .Button {
  max-width: 50%;
}

.InvalidTokenError .Buttons .Button {
  margin: 0 10px;
  padding: 12px 0;
  flex: 1;
}

.InvalidTokenError .modal-body {
  padding-bottom: 35px;
}
