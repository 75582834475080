.AddressCard .AddressElements {
  display: flex;
  border-radius: 16px;
  background-color: var(--color-content);
  padding: 18px;
  width: 600px;
  color: var(--color-text-primary);
  margin-bottom: 8px;
  border: none;
}

.Account .AddressCard .AddressElements {
  background-color: var(--color-secondary);
}

.AddressCard .AddressElements li {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}

.AddressCard .AddressElements .fa-trash {
  font-size: 1.125em;
}

.AddressCard.selectable .AddressElements {
  display: flex;
  border: none;
  margin-top: -1px; /* Prevent double borders */
  background-color: var(--color-content);
  padding: 15px 15px 15px 20px;
  text-decoration: none;
  color: black;
  margin-bottom: 8px;
  width: unset;
  opacity: 1;
}

.AddressElements .select {
  height: 100%;
}

.AddressCard .AddressElements.selected {
  border: none !important;
  opacity: 1;
}

.AddressCard.selectable .AddressElements .AddressText {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.AddressCard .AddressElements .AddressText .addressName,
.AddressCard .AddressElements .AddressText .address.hasAddressName,
.AddressCard.selectable .AddressElements .AddressText .addressName,
.AddressCard.selectable .AddressElements .AddressText .address.hasAddressName {
  font-weight: 400;
}
.AddressCard .AddressText .address {
  margin-bottom: 0;
}

.AddressCard.selectable .AddressElements.selected .AddressText .addressName,
.AddressCard.selectable .AddressElements.selected .AddressText .address.hasAddressName {
  color: var(--color-text-grey);
}

.AddressCard.selectable .AddressElements .AddressText .address {
  font-size: 14px;
}

.AddressCard.selectable .AddressElements .Button {
  width: unset !important;
  padding: 8px 16px;
  height: 36px;
}

.AddressCard.selectable .AddressElements > li {
  margin: 2px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 992px) {
  .AddressCard .AddressElements {
    width: unset;
  }

  .AddressCard.selectable .AddressElements .Button {
    padding: 12px 20px;
  }
}
