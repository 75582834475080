/* #map */
.Map {
  display: flex;
  flex: 3;
}

.MapElement {
  display: flex;
  flex: 1;
  outline: none;
  overflow: hidden;
}

@media (max-width: 992px) {
  .Map {
    display: none;
  }
}
