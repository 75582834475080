.RewardModal .modal-body {
  display: flex;
  padding: 0;
  overflow: hidden;
}

.RewardModal .modal-control {
  position: absolute;
}

.RewardModal .ImageContainer {
  flex: 4;
}

.RewardModal .ImageContainer img {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}

.RewardModal .Content {
  flex: 5;
  padding-bottom: 8px;
}

.RewardModal .Content .Header {
  background-color: none;
  color: var(--color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 40px 8px;
}

.RewardModal .TextContainer {
  text-align: left;
  padding: 16px 40px;
  width: 85%;
  margin: 0 auto;
}

.CollectionModal .TextContainer .CollectionName {
  text-align: center;
  font-weight: 600;
}

.CollectionModal .ValidityRow {
  display: flex;
  justify-content: space-between;
}

.CollectionModal .Validity,
.CollectionModal .Remaining {
  display: inline;
}

.CollectionModal .Remaining {
  font-style: italic;
  margin-left: auto;
}

.CollectionModal .Description {
  padding-top: 0;
}

.RewardModal .ProgressBarBackground {
  height: 24px;
  border-radius: 24px;
  margin: 24px 0 16px;
}

.RewardModal .ProgressBar {
  height: 24px;
  border-radius: 24px;
}

/* Tablet and mobile */
@media (max-width: 767px) {
  .RewardModal .TextContainer {
    width: 100%;
    padding: 16px;
  }

  .RewardModal .TextContainer .RewardName {
    text-align: left;
  }
}
