@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    -webkit-appearance: -apple-pay-button;
    border-radius: none;
  }
  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
  .apple-pay-button.grey {
    opacity: 0.5;
    pointer-events: none;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: none;
    padding: 0px;
    box-sizing: border-box;
    min-width: 180px;
    min-height: 32px;
    max-height: 54px;
    height: 100%;
    width: 100%;
  }
  .apple-pay-button-black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
  .apple-pay-button.grey {
    opacity: 0.5;
    pointer-events: none;
  }
}
