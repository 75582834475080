.DiningChoiceChangeModal .DiningChoiceChangeContent {
  margin: 24px auto;
}

.DiningChoiceChangeButton {
  width: 80px;
  padding: 8px 24px;
  margin-left: 24px;
}

.DiningChoiceChangeButton.Button {
  padding: 8px 60px;
}

/*--- Desktop only ---*/
@media (min-width: 993px) {
  .DiningChoiceChangeModal .Button {
    margin: 0 8px;
  }
}
