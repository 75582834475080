.DietInformation {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: absolute;
  bottom: 2px;
  z-index: 1000;
  display: none;
}

.DietInformation ul {
  display: flex;
  justify-content: flex-end;
  margin-right: 4px;
}

.DietInformation ul li {
  margin-right: 4px;
}

.DietInformation ul li:last-of-type {
  margin-right: 0;
}

.DietInformation ul li img {
  max-width: 100%;
  height: 14px;
}

.Personalize .DietInformation ul li img,
.MenuDetails .DietInformation ul li img {
  height: 26px;
}
