.NameFavorite .modal-dialog {
  width: 420px;
}
.NameFavorite.modal .modal-dialog .modal-body {
  padding: 25px;
  background-color: var(--color-background);
}
.NameFavorite .modal-content p {
  padding-bottom: 6px;
}
.NameFavorite .modal-body > ul {
  margin: 16px 0 0;
}
.NameFavorite .modal-input {
  margin-bottom: 20px;
  width: 100%;
  margin-top: 7px;
  max-width: 320px;
}
.NameFavorite .modal-body > .Button {
  width: 80%;
}
.NameFavorite .OrderItem > ul > li:nth-child(2) p {
  padding-bottom: 0;
}

.NameFavorite .NameFavoriteButtonRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.NameFavorite .NameFavoriteButtonRow .Button {
  width: 150px;
  margin: 0 8px;
}

.NameFavorite .OrderItem .OrderItemIngredients {
  padding-right: 8px;
}

@media (max-width: 767px) {
  .NameFavorite .modal-dialog {
    width: 100vw;
    margin: 50px 0 0 0;
  }
  .NameFavorite .modal-content {
    max-width: 360px !important;
  }

  .NameFavorite .NameFavoriteButtonRow {
    flex-direction: column-reverse;
  }

  .NameFavorite .NameFavoriteButtonRow .Button {
    width: 100%;
    margin: 4px 0px;
  }
}
