.FacebookPopupContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.FacebookPopupContainer .FacebookPopupText {
  max-width: 80%;
  text-align: center;
}
