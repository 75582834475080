.CarbonFootprint {
  color: var(--color-dark);
}

.MenuDetailsView .CarbonFootprint {
  padding: 16px 20px;
}

.IngredientModal.NutritionalModal .CarbonFootprint {
  text-align: center;
}

.IngredientModal .IngredientInfo .CarbonFootprint {
  display: none;
}

.NutritionalModal .CarbonFootprint {
  padding-bottom: 14px;
}

.CarbonFootprint .CarbonFootprintTitle {
  font-weight: 600;
}

/* Mobile Portrait and Landscape */
@media (max-width: 992px) {
  .MenuDetailsView .CarbonFootprint {
    display: none;
  }

  .IngredientModal .IngredientInfo .CarbonFootprint {
    display: block;
    text-align: center;
  }
}
