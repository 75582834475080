.GglocationName li {
  color: var(--color-text-grey);
  background-color: transparent;
  border-bottom-right-radius: 10px;
  padding: 0;
  display: inline-block;
  z-index: 998;
  position: relative;
}
.GglocationName li .Name {
  vertical-align: middle;
  font-size: 1.2em;
  font-weight: bold;
}
