.Cart .CartButton .Button {
  padding: 12px 16px;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  font-size: initial;
}

.Cart .CartButton .Button.apple-pay-button {
  margin: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
}

.Cart .CartButton .Button.loading .fa-spin {
  top: 15px;
}

.Cart .CartButton {
  margin-top: 0px;
  margin-left: 0;
  width: 100%;
  display: flex;
  height: 54px;
}

.Cart .CartButton .MenuButton,
.Cart .CartButton .MainButton {
  width: 50%;
  position: relative;
}

.Cart .CartButton .MenuButton {
  flex: 1;
}

.Cart .CartButton .MainButton {
  flex: 1;
}

.Cart .CartButton .MenuButton {
  margin-right: 0px;
}

.Cart .CartButton .MenuButton .Button > span,
.Cart .CartButton .MainButton .Button > span {
  display: flex;
}

.Cart .CartButton .MenuButton .Button .Caption,
.Cart .CartButton .MainButton .Button .Caption {
  flex: 1 1 100%;
}

.Cart .CartButton .MainButton .Button .Caption {
  padding-left: 16px;
}

.Cart .CartButton .MenuButton .Button .lnr {
  margin-left: 5px;
}

.Cart .CartButton .MainButton .Button .lnr {
  margin-right: 5px;
}

.Cart .CartButton p {
  color: var(--color-text-primary);
  font-size: 0.8em;
  margin-top: 15px;
}

/*--- Desktop Only ---*/
@media (min-width: 767px) {
  .Cart .CartPaymentInfo .CartButton .MenuButton {
    display: none;
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .Cart .CartButton .MenuButton .Button,
  .Cart .CartButton .MainButton .Button {
    border-radius: 0;
  }

  .Cart .CartButton .MenuButton {
    margin-right: 0px;
  }
}
