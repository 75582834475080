.LoyaltyRewardWrapper {
  margin: 4px auto;
}

.LoyaltyReward {
  display: flex;
  background-color: var(--color-content);
  margin: 0;
  border-radius: 16px;
  padding-right: 10px;
  min-height: 80px;
  overflow: hidden;
  align-items: center;
  box-shadow: none;
  border: none;
}

.LoyaltyReward .LoyaltyRewardBody {
  flex: 1;
  padding: 8px 16px;
  font-size: 0.85em;
}

.LoyaltyReward .LoyaltyRewardBody p:last-child {
  margin: 0;
}

.LoyaltyReward .LoyaltyRewardButton {
  padding: 8px 16px;
  height: 80%;
  font-size: 0.85em;
}

.LoyaltyReward.Small .Name {
  margin-bottom: 0;
  vertical-align: middle;
  display: inline-block;
}

.LoyaltyReward.Small .Validity {
  display: none;
}
