.PastOrders {
  max-width: 658px;
  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.PastOrders .Title {
  color: var(--color-text-primary);
  margin-bottom: 12px;
}

.PastOrders .tabsRow {
  display: flex;
  border-bottom: none;
  margin-bottom: 0px;
}

.PastOrders .tabsRow .Button:first-child {
  margin-right: 0px;
}

.PastOrders .tabsRow .Button {
  font-size: 1.5em;
  font-weight: bold;
  padding: 8px 20px;
  flex: 0 1 auto;
  background-color: none;
  color: var(--color-primary);
  border-radius: 16px 16px 0 0;
  clip-path: none;
  border: none;
  text-transform: uppercase;
  padding-left: 0;
}

.PastOrders .tabsRow .Button + .Button {
  margin-left: 24px;
}

.PastOrders .tabsRow .Button.selected,
.PastOrders .tabsRow .Button.selected:focus {
  background-color: transparent;
  margin-top: 0px;
  border: none !important;
  color: var(--color-light);
}

.PastOrders .tabsRow .Button > span {
  justify-content: flex-start;
}

.PastOrders .tabsRow .Button.selected > span {
  padding-top: 0px;
}

.PastOrders .tabsRow .Button.tabsButton:hover,
.PastOrders .tabsRow .Button.tabsButton:active {
  background-color: transparent;
  color: var(--color-light);
}

.PastOrders .PastOrderListWrapper,
.PastOrders .PastScansWrapper {
  margin-top: 8px;
  overflow-y: auto;
}

.PastOrders .PastOrderEmptyContainer {
  padding-top: 4px;
}

.PastOrders .PastOrderEmptyContainer .btn-primary {
  margin-top: 24px;
  max-width: 250px;
}

@media (max-width: 767px) {
  .PastOrders .tabsRow {
    width: 100%;
    padding: 0 16px;
  }

  .PastOrders .tabsRow .Button > span {
    justify-content: center;
  }

  .PastOrders .tabsRow .Button {
    flex: 1;
    padding: 8px;
  }

  .PastOrders .tabsRow .Button + .Button {
    margin-left: 0;
  }

  .PastOrders .PastOrderEmptyContainer {
    text-align: center;
  }
}
