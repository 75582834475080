.PersonalizePreferenceGroupRadioSwitch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 46px;
  padding-left: 8px;
}

.PersonalizePreferenceGroupRadioSwitch .PreferenceGroupRadioSwitch {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
}

.PersonalizePreferenceGroupRadioSwitch .PreferenceGroupRadioSwitch + .PreferenceGroupRadioSwitch {
  margin-left: 24px;
}

.PersonalizePreferenceGroupRadioSwitch .PreferenceGroupRadioSwitch input[type='radio'] {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  background-clip: content-box;
  padding: 3px;
  margin: 0 8px 0 0;
  outline: none !important;
  cursor: pointer;
}

.PersonalizePreferenceGroupRadioSwitch .PreferenceGroupRadioSwitch input[type='radio']:checked {
  background-color: var(--color-primary);
}

.recommended-text {
  display: inline-block;
  font-size: 10px;
}

@media (max-width: 330px) {
  .recommended-text {
    font-size: 8px;
  }
}
