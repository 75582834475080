.popUpWrapper {
  display: flex;
  margin-top: 24px;
  margin-left: 5%;
  margin-right: 5%;
}

.popUp {
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-content);
  padding: 16px 24px;
  color: var(--color-text-grey);
  text-align: center;
  font-size: 1em;
  border-radius: 16px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
  display: flex;
}

.EmailVerificationPopUp .popUp strong {
  font-weight: 600;
  overflow-wrap: anywhere;
}

.popUp .blue-link {
  margin-left: 32px;
}

.closePopUp {
  margin-left: 16px;
}
