.Delivery {
  padding: 25px 24px 30px 24px;
  font-size: 16px;
}

.Delivery .Addresses .title-function {
  margin: 16px auto;
}

.Delivery .header {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: start;
  line-height: 1em;
  margin-bottom: 4px;
}

.Delivery .deliveryIcon {
  width: 100px;
  margin-right: 15px;
}

.Delivery .heading {
  font-weight: 700;
  color: var(--color-text-primary);
  line-height: 18px;
  margin-bottom: 7px;
  font-size: 19px;
}

.Delivery .heading.semibold {
  font-weight: 600;
}

.Delivery .copy {
  font-size: 14px;
  color: #ec812d;
  line-height: 18px;
  margin-bottom: 7px;
}

.Delivery .copy.small {
  font-size: 12px;
  color: #ec812d;
  line-height: 1.3em;
}

.Delivery .or {
  font-style: italic;
  margin: 10px auto;
}

.Delivery .blue-link {
  margin-top: 4px;
}

.Delivery .Input {
  width: 100%;
  height: 55px;
}

.Delivery .Button {
  height: 55px;
}

.Delivery .GetLocationButton svg {
  width: 20px;
  margin-right: 8px;
}

.Delivery .Input::placeholder {
  font-size: 16px;
  font-style: italic;
  text-align: center;
}

.Delivery .info {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 0px 0 16px;
}

.Delivery .info img {
  width: 12px;
}

.Delivery .info span {
  margin: 0px 2px;
}

.Delivery .info + p {
  margin: 8px 0px 16px;
}

.Delivery .GglocationsSelect {
  max-width: unset;
  width: 100%;
  margin: 0;
}

.Delivery .GglocationsSelect .Select {
  border-radius: 60px;
}

.Delivery .Button.btn-primary {
  width: 100%;
}

.Delivery .Button.btn-secondary {
  width: 100%;
  margin-top: 16px;
  background-color: var(--color-background);
}

.Delivery .Button.disabled {
  background-color: #969696;
  border-color: #969696;
}

.Delivery .manage-address {
  color: #0392ab;
  font-size: 16px;
  margin: 12px 12px 24px 20px;
}

.Delivery .manage-address span {
  text-decoration: underline;
}

.Delivery .manage-address.glyphicon.glyphicon-plus-sign.semibold::before {
  margin-right: 5px;
}

.Delivery .AddressCard .AddressElements {
  border: 1px solid var(--color-primary) !important;
}

.Delivery .AddressCard .AddressElements.selected {
  border: 2px solid var(--color-primary) !important;
  background-color: var(--color-background);
}
