.LandingPage .LandingPageMenu {
  margin: 16px 16px;
}

.LandingPage .MenuItem {
  border: 1px solid black;
  border-radius: 16px;
  overflow: hidden;
}

.LandingPageMenu .MenuItem h5 {
  font-weight: bold;
}

.LandingPageMenu .category-name h3 {
  font-weight: bold;
}

.LandingPageMenu .category-name {
  padding: 24px 10px 8px 10px;
}

.LandingPageMenu .category-desc {
  padding-left: 8px;
}
