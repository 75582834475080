.SignIn ul.mobile-verification {
  margin-top: 30px;
  margin-bottom: 30px;
}
.SignIn .input-code-alert li input {
  border-bottom: 1px solid var(--color-text-error) !important;
  color: var(--color-text-error) !important;
}

.SignIn ul.mobile-verification li {
  display: inline-block;
  width: 50px;
  margin: 0 3px;
}
.SignIn ul.mobile-verification li input {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0;
  text-align: center;
  font-size: 1.6em;
  padding: 4px;
}
