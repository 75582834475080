.TimeSlotLabel {
  padding: 23px;
  background-color: var(--color-content);
  border-radius: 16px;
}

.TimeSlotLabel .TimeSlotLabelLine + .TimeSlotLabelLine {
  margin-top: 2px;
}

.TimeSlotLabel .TimeSlotLabelLine span {
  font-weight: 600;
}
