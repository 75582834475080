.AddPaymentMethodFormModal .modal-dialog {
  width: 100%;
  height: 100%;
  max-width: none;
}

.AddPaymentMethodFormModal .modal-content {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: var(--color-content);
  border-radius: 0;
  max-width: none;
  overflow: auto;
  display: flex;
}

.AddPaymentMethodFormModal .modal-body {
  padding: 0px;
  overflow: auto;
  border-radius: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.AddPaymentMethodFormModal .TopBar .TopBarRightButton {
  visibility: hidden;
  overflow: hidden;
}

.AddPaymentMethodFormModalBody {
  padding-top: 32px;
  overflow: auto;
}

.AddPaymentMethodFormModalBody .AddPaymentMethodForm {
  max-width: 520px;
  margin: 0 auto;
}

/* Mobile Portrait Only */
@media (max-width: 479px) {
  .AddPaymentMethodFormModalBody .AddPaymentMethodFormModalTitle {
    font-size: 1em;
  }
}
