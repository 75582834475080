.CartPaymentInfo {
  grid-area: cart-payment-info;
  margin: 0 12px 0 24px;
}

.CartPaymentInfo .CartPaymentDetails {
  display: flex;
  flex-direction: column;
}

/*--- Desktop and Tablet Landscape ---*/
@media (max-width: 992px) and (min-width: 768px) {
  .CartPaymentInfo {
    margin: 0 8px 0 16px;
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .CartPaymentInfo {
    background-color: transparent;
    box-shadow: none;
    z-index: 10;
    border-radius: 0;
    margin: 0;
  }

  .CartPaymentInfo .CartPaymentDetails {
    padding: 0;
  }

  .CartPaymentInfo .CartPriceLine.CartTotal,
  .CartPaymentInfo .Tax {
    display: none;
  }

  .CartPaymentInfo .CartMinOrderBannerWrapper {
    display: none;
  }
}
