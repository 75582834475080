.OrderPayment {
  margin-top: 0;
  padding-bottom: 16px;
}

.OrderPayment .surcharges,
.OrderPayment .discounts {
  font-size: 12px;
  line-height: 18px;
}

.OrderPayment .OrderPaymentTitle {
  display: flex;
  justify-content: space-between;
  margin: 16px 32px 0;
  align-items: center;
}

.Order .OrderPayment .SectionSeparator {
  margin: 0;
}

.OrderPayment .OrderPaymentTitle .paymentMethodLabel {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9em;
  align-self: center;
  align-items: center;
  display: flex;
}

.OrderPayment .CardIcon {
  margin-right: 6px;
}

.OrderPayment .GooglePayIcon {
  display: block;
  height: 38px;
  margin: 0;
  padding: 0;
}

.OrderPayment .ApplePayIcon {
  height: 24px;
  margin-right: 6px;
}

/*--- Mobile and Tablet ---*/
@media (max-width: 992px) {
  .OrderPayment .OrderPaymentTitle {
    margin: 16px 24px 0;
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .OrderPayment {
    padding-bottom: 0;
  }
}
