.DietConflictResolver .modal-dialog {
  width: 500px;
}
.DietConflictResolver .modal-body {
  padding: 32px 48px;
  text-align: left;
}
.DietConflictResolver ul.diet-item-list {
  margin: 16px auto;
  display: flex;
  overflow: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  width: min-content;
  max-width: 100%;
}
.DietConflictResolver ul.diet-item-list > li {
  display: flex;
}
.DietConflictResolver ul.diet-item-list > li + li {
  margin-left: 8px;
}
.DietConflictResolver ul.diet-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--color-panel-primary);
  box-shadow: none;
  border: 1px solid var(--color-secondary);
  border-radius: 16px;
  overflow: hidden;
  text-align: left;
  margin-bottom: 0px;
}
.DietConflictResolver ul.diet-item li.diet-item-image {
  width: 136px;
  height: 80px;
  display: flex;
}
.DietConflictResolver ul.diet-item li img {
  height: auto;
  object-fit: cover;
  flex: 1;
}
.DietConflictResolver ul.diet-item li.diet-item-details {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}
.DietConflictResolver ul.diet-item h5 {
  font-size: 1.1em;
  line-height: 1.6em;
  margin-bottom: 1px;
  margin-top: 10px;
}

.DietConflictResolver ul.diet-item .dietConflictLabel {
  color: #7a7a7a;
}
.DietConflictResolver ul.diet-item li:last-child button {
  width: 100%;
  height: 100%;
  color: var(--color-text-hyperlink);
}
.DietConflictResolver ul.button {
  display: flex;
}
.DietConflictResolver ul.button li {
  flex: 1;
  margin: 8px 0;
}
.DietConflictResolver ul.button > li + li {
  margin-left: 16px;
}
.DietConflictResolver ul.button li button {
  padding: 12px !important;
  height: 100%;
  width: 100%;
}

.DietConflictResolver .NutritionalChatButton {
  width: 100%;
  margin-top: 8px;
  padding: 12px 60px;
  color: #128c7e;
  border: solid 1px #128c7e;
  border-color: #128c7e;
  border-radius: 60px;
  background-color: transparent;
}

.DietConflictResolver .NutritionalChatButton .fa {
  margin-right: 4px;
  font-size: 1.2em;
}

.DietConflictResolver .DietConflictTitle {
  margin-bottom: 24px;
  text-align: center;
}

.DietConflictResolver .dietConflictLabel::first-letter {
  text-transform: capitalize;
}

.DietConflictResolver .DietConflictDesc {
  margin: 8px 0 12px;
}

.DietConflictResolver .DietTag {
  padding: 8px 16px;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .DietConflictResolver .modal-dialog {
    max-width: 360px !important;
    width: 90%;
  }
  .DietConflictResolver .modal-body {
    padding: 16px;
    text-align: left;
  }
  .DietConflictResolver .modal-content {
    margin: 30px 0 20px;
  }

  .DietConflictResolver .modal-body {
    padding: 16px;
  }

  .DietConflictResolver .NutritionalChatButton {
    margin-bottom: 16px;
    padding: 12px 16px;
    font-size: 0.9em;
  }

  .DietConflictResolver ul.diet-item-list {
    flex-direction: column;
    margin: 16px 0;
    width: 100%;
    max-height: 45vh;
  }

  .DietConflictResolver ul.diet-item-list > li + li {
    margin-left: 0px;
    margin-top: 8px;
  }

  .DietConflictResolver ul.diet-item {
    flex-direction: row;
  }

  .DietConflictResolver ul.diet-item li.diet-item-details {
    justify-content: center;
  }

  .DietConflictResolver ul.diet-item li.diet-item-image {
    width: 80px;
    height: 80px;
    display: flex;
    padding: 6px;
  }
}
