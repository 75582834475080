.MenuDetailsView {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.MenuDetailsView .MenuContentFrame {
  padding-bottom: 40px;
}

.MenuDetailsView .IngredientsPicker {
  padding: 2px 20px;
  width: 100%;
  text-align: left;
  justify-content: left;
}

.MenuDetailsContentHeader {
  padding: 0 20px 4px;
}

.MenuDetailsContentHeader .MenuDetailsHeaderDesc {
  margin: 8px 0;
}

/* Mobile Portrait and Landscape */
@media (max-width: 992px) {
  .MenuDetailsView {
    padding-bottom: 160px;
  }

  .MenuDetailsView .DietButtonVariantToggle,
  .Cyo .CustomizeRight > .DietButtonVariantToggle {
    display: initial;
    position: fixed;
    margin: 0;
    padding: 8px 16px;
    border-radius: 0;
    bottom: 54px;
    left: 0;
    right: 0;
    z-index: 999;
    font-size: 0.9em;
  }

  .MenuDetailsContentHeader {
    display: none;
  }
}
