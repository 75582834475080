.QuantitySelector {
  width: 100%;
  background-color: var(--color-secondary);
  margin-bottom: 0;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 108px;
}

.QuantitySelector .section {
  max-width: 600px;
  position: relative;
  padding-bottom: 0px;
  height: 100%;
  display: flex;
}

.QuantitySelector ul {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: var(--color-text-primary);
}

.QuantitySelector ul li.counter {
  flex: 1;
  font-weight: bold;
  font-size: 1.8em;
}

.QuantitySelector ul li.counter ul li:first-child,
.QuantitySelector ul li.counter ul li {
  display: flex;
  align-items: center;
}

.QuantitySelector ul li.counter ul li:nth-child(2) {
  padding: 0 32px;
}

.QuantitySelector ul li.counter button {
  opacity: 1;
}

.QuantitySelector ul li.counter button:hover {
  opacity: 1;
}

.QuantitySelector ul li.counter button .fa {
  color: var(--color-text-primary);
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .QuantitySelector {
    width: 100%;
    position: absolute;
    bottom: 54px;
    left: 0;
    height: 54px;
    z-index: 9999;
  }

  .QuantitySelector ul {
    justify-content: space-evenly;
  }

  .QuantitySelector ul li.counter {
    font-size: 1.5em;
  }

  .QuantitySelector ul li.counter ul li:nth-child(2) {
    padding: 0;
  }

  .QuantitySelector .section {
    margin: auto;
    padding: 6px 16px;
  }
}
