body {
  margin: 0px;
}

.AddressMap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  float: left;
}

.AddressMap #mapSearchContainer {
  position: fixed;
  top: 20px;
  right: 40px;
  height: 30px;
  width: 180px;
  z-index: 110;
  font-size: 10pt;
  color: #5d5d5d;
  border: solid 1px #bbbbbb;
  background-color: #f8f8f8;
}

.AddressMap .leaflet-popup-content-wrapper {
  background-color: var(--color-background);
  color: var(--color-text-grey);
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  min-height: 56px;
  min-width: 120px;
  display: flex;
  align-items: center;
  font-size: 16px;
  transform: translateY(-50%);
}

.AddressMap .leaflet-popup-tip {
  background: var(--color-secondary);
}

.AddressMap .leaflet-pane > svg path {
  fill: var(--color-primary);
  fill-opacity: 0.4;
  stroke: var(--color-primary);
}

.AddressMap #map {
  width: 100%;
  height: 100%;
  z-index: 100;
}

.AddressMap .pointer {
  position: absolute;
  top: 86px;
  left: 60px;
  z-index: 99999;
}

.AddAddressPopup .leaflet-popup-tip-container {
  display: none;
}

.AddressMap .AddAddressPopup {
  top: 0px !important;
  left: 40px !important;
}

/* Tablet and Mobile */
@media (max-width: 992px) {
  .AddressMap {
    width: 100%;
    height: 200px;
    display: none;
  }
}

/* Tablet Vertical */
@media (max-width: 992px) and (min-height: 992px) {
  .AddressMap {
    height: 400px;
  }
}
