.Input {
  padding: 12px !important;
  background-color: var(--color-background) !important;
  border: 1px solid var(--color-primary) !important;
  border-radius: 60px;
  box-shadow: none;
  color: var(--color-text-grey);
  text-align: left;
}

.Input::placeholder {
  color: #9eb5a6;
}

.Input.hasError {
  border: 1px solid #d85a5b !important;
}

.Input.hasError {
  color: var(--color-text-grey);
}

.Input.hasError::placeholder {
  color: #d85a5b;
}

.Input:focus {
  outline: none;
}

.Input span.caret {
  color: var(--color-secondary);
  position: absolute;
  right: 20px;
  top: 20px;
  border-top: 6px dashed;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}
