.PaymentMethodForm {
  padding: 16px;
}

.PaymentMethodForm .PaymentMethodFormRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  margin-bottom: 16px;
  gap: 16px;
}

.PaymentMethodForm .PaymentMethodFormColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.PaymentMethodForm .PaymentMethodFormColumn .PaymentMethodFormField {
  width: 100%;
  margin-bottom: 0;
}

.PaymentMethodForm .PaymentMethodFormColumn .PaymentMethodFormFieldLabel {
  margin: 0 8px;
  text-align: left;
}

.PaymentMethodForm .PaymentMethodFormColumn .PaymentMethodFormFieldError {
  margin: 0 8px -8px;
}

.PaymentMethodForm .PaymentMethodFormOtherError {
  margin: 0 0px 8px;
  text-align: center;
}

.PaymentMethodForm .PaymentMEthodFormSubmitBtn {
  margin-top: 8px;
}
