.MenuNavbarWrapper {
  position: sticky;
  top: 0;
  height: 56px;
  z-index: 999;
  display: none;
}

.MenuNavbarWrapper.topBorderVisible {
  border-top: 1px solid #ffffff;
}

.MenuNavbarCategories {
  width: 100%;
  overflow: hidden;
  display: flex;
}

.MenuNavbar {
  width: 100%;
  background-color: var(--color-primary);
  position: relative;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  z-index: 99999;
  padding-left: 28px;
}

.MenuNavbar ul {
  min-width: 100%;
  display: flex;
  padding-right: 25px;
}

.MenuNavbar ul li {
  padding: 0 5px 0px 10px;
}

.MenuNavbar ul li a,
.MenuNavbar ul li button {
  color: var(--color-text-contrast);
  height: 56px;
  display: flex;
  align-items: center;
  justify-items: center;
}
.MenuNavbarItemName {
  font-size: 1em !important;
}
.MenuNavbarItemName.selectedNavbarItem {
  padding-top: 3px;
  border-bottom: 3px solid var(--color-light);
}
.MenuNavbar-lg ul li:first-child {
  padding: 0 10px 12px 22px;
}

.MenuNavbar-lg ul li:last-child span {
  margin-left: 6px;
}

.MenuNavbar ul li.selected {
  border-bottom: 3px solid #92d27f;
}

.MenuNavbar ul li.selected a {
  color: #92d27f;
}

.category-name {
  padding: 24px 4px 8px;
  text-align: left;
  text-transform: uppercase;
}

.MenuSubnav {
  width: 100%;
  height: 56px;
  padding: 20px 0;
  background-color: #f2ede9;
  vertical-align: middle;
  z-index: 99999;
  border-bottom: 1px solid #e0d7d0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  z-index: 99999;
}
.MenuSubnav.hidden {
  display: none;
}
.MenuSubnav ul {
  min-width: 100%;
  display: table;
}
.MenuSubnav ul li {
  display: inline-block;
  padding: 0 5px 11px 6px;
  margin-right: 5px;
}
.MenuSubnav ul li a {
  font-size: 0.95em;
  color: var(--color-text-primary);
  padding: 10px 5px;
}
.MenuSubnav ul li a:hover {
  position: relative;
  top: -3px;
}

.MenuSubnav ul li:first-child {
  padding-left: 18px;
}

/*--- Desktop only ---*/
@media (min-width: 993px) {
  /*--- Disable translation for desktop mode ---*/
  .MenuNavbarCategories .react-draggable {
    transform: translate(0px) !important;
  }
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .MenuNavbar {
    padding-left: 16px;
  }
  .MenuNavbarWrapper {
    border-top: 1px solid #ffffff;
  }

  .MenuSubnav {
    margin-top: -8px;
    text-align: center;
  }
}

/* Mobile Landscape and Portrait */
@media (max-width: 767px) {
  .MenuNavbar {
    overflow: initial;
  }
}
