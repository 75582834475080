.OrderCancel .modal-dialog {
  width: 420px;
}
.OrderCancel.modal .modal-dialog .modal-body {
  display: flex;
  flex-direction: column;
  padding: 32px;
}
.OrderCancel .modal-content p {
  padding-bottom: 12px;
}
.OrderCancel .modal-body > ul {
  margin-bottom: 15px;
}
.OrderCancel .modal-input {
  margin-bottom: 20px;
  width: 95%;
  margin-top: 7px;
}
.OrderCancel .modal-body > .Button {
  width: 80%;
}

.OrderCancel .ButtonBar {
  margin-top: 6px;
  display: flex;
}

.OrderCancel .ButtonBar .Button {
  margin: 0 5px;
  flex: 1 1 auto;
  width: 40%;
}

.OrderCancel .OrderCancelModalTitle {
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .OrderCancel .modal-dialog {
    width: 100vw;
    margin: 50px 0 0 0;
  }
  .OrderCancel .modal-content {
    max-width: 360px !important;
  }
}
