.CurrentOrderItem {
  display: flex;
}

.CurrentOrderItem .CurrentOrderItemName {
  flex: 6;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.CurrentOrderItem .CurrentOrderItemQuantity,
.CurrentOrderItem .CurrentOrderItemPrice {
  flex: 1;
  text-align: right;
}

.CurrentOrderItem .CurrentOrderItemPrice {
  flex: 2;
}
