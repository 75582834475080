.NutritionalSection {
  clear: both;
  padding: 6px 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: calc(100vh - 56px);
  overflow-y: auto;
}
.NutritionalSection p {
  color: #d6bca3;
}

.NutrientsList {
  column-count: 2;
  flex-wrap: wrap;
}

.NutrientsList ul {
  display: flex;
}

.NutrientsList ul li {
  display: flex;
  color: var(--color-text-grey);
  padding: 5px 0;
  flex: 1;
}

.NutrientsList ul li.NutrientName h6 {
  font-weight: 600;
}

.NutritionalSection .NutritionalDisclaimer {
  margin: 16px 0;
  font-size: 0.875em;
  color: var(--color-text-grey);
}

.NutritionalDisclaimer {
  font-style: italic;
}
.NutritionalDisclaimer span {
  font-weight: 600;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .NutritionalSection {
    position: initial;
    text-align: left;
    padding: 0 10px;
    overflow: visible;
  }
  .NutritionalSection .content-top {
    margin-left: 0;
  }
  .NutrientsList {
    column-count: unset;
  }
  .NutrientsList ul li {
    line-height: 1.6em;
  }
  .NutrientsList ul li.NutrientValue {
    justify-content: flex-end;
  }
}
