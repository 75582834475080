.google-pay-container {
  padding: 4px 12px;
  opacity: 1;
  height: 100%;
  pointer-events: auto;
  transition:
    opacity 0.3s,
    pointer-events 0.3s;
}

.google-pay-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}
