.SignIn .ForgetPasswordLink > span {
  font-weight: 600;
}

.SignIn .ForgetPasswordLink:hover {
  text-decoration: none !important;
}

.SignIn .ForgetPasswordLink:hover > span {
  font-weight: 700;
}
