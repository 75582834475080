.EditFavorite .modal-dialog {
  width: 420px;
}
.EditFavorite.modal .modal-dialog .modal-body {
  padding: 25px;
}
.EditFavorite .modal-content p {
  padding-bottom: 6px;
}
.EditFavorite .modal-body > ul {
  margin-bottom: 15px;
}
.EditFavorite .modal-input {
  margin-bottom: 20px;
  width: 100%;
  margin-top: 7px;
}
.EditFavorite .modal-body > .Button {
  width: 80%;
}

.EditFavorite .EditFavoriteButtonRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.EditFavorite .EditFavoriteButtonRow .Button {
  width: 150px;
  margin: 0 8px;
}

@media (max-width: 767px) {
  .EditFavorite .modal-dialog {
    width: 100vw;
    margin: 50px 0 0 0;
  }
  .EditFavorite .modal-content {
    max-width: 360px !important;
  }

  .EditFavorite .NameFavoriteButtonRow {
    flex-direction: column-reverse;
  }

  .EditFavorite .NameFavoriteButtonRow .Button {
    width: 100%;
    margin: 4px 0px;
  }
}
