@import '../../css/bootstrap.css';
@import '../../css/animations.css';

/* Import Work Sans Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,600;0,700;0,800;0,900&display=swap');

@font-face {
  font-family: 'Sharp Grotesk SmBold25';
  src: url('../../fonts/SharpGrotesk/SharpGroteskSmBold25.ttf') format('truetype');
}

@font-face {
  font-family: 'Sharp Grotesk SmBold15';
  src: url('../../fonts/SharpGrotesk/SharpGroteskSmBold15.ttf') format('truetype');
}

@font-face {
  font-family: 'Sharp Grotesk Bold15';
  src: url('../../fonts/SharpGrotesk/SharpGroteskBold15.ttf') format('truetype');
}

@font-face {
  font-family: 'Sharp Grotesk Medium15';
  src: url('../../fonts/SharpGrotesk/SharpGroteskMedium15.ttf') format('truetype');
}

@font-face {
  font-family: 'Calafia';
  src: url('../../fonts/Calafia/Calafia-Regular.otf') format('truetype');
}

:root {
  --color-primary: #0d4520;
  --color-secondary: #f4eedc;
  --color-text-primary: #0d4520;
  --color-text-secondary: #f4eedc;
  --color-text-contrast: #fdfdfd;
  --color-text-grey: #2f2f2f;
  --color-text-error: #ff4045;
  --color-text-hyperlink: #ef6700;
  --color-light: #ccdb74;
  --color-dark: #c49876;
  --color-contrast: #0a371a;
  --color-beige: #a5b59c;
  --color-content: #fffaf1;
  --color-cyo-content: #f4eedc;
  --color-background: #fffaf1;
  --color-modal-primary: #fdfdfd;
  --color-modal-cart: #f5f5f5;
  --color-panel-primary: #fffdf8;
  --color-panel-secondary: #f3f6f4;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--color-background);
  overflow: hidden auto;
  padding-bottom: 0;
  font-size: 15.5px;
  line-height: 20px;
  vertical-align: middle;
  touch-action: manipulation;
  color: var(--color-text-grey);
}

button {
  padding: 0;
}

::placeholder {
  font-family: 'Work Sans';
}

::selection {
  font-family: 'Work Sans';
  font-weight: 600;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.SharpGroteskSmBold15,
.SharpGroteskSmBold15 .MuiTypography-root {
  font-family: 'Sharp Grotesk SmBold15' !important;
  letter-spacing: 0.05em;
}

.SharpGroteskBold15,
.SharpGroteskBold15 .MuiTypography-root {
  font-family: 'Sharp Grotesk Bold15' !important;
  letter-spacing: 0.05em;
}

.CalafiaSectionTitle.SectionSeparator {
  overflow: visible;
  text-transform: capitalize !important;
  z-index: 10;
  margin-bottom: -4px;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
}

.ant-time-picker-panel-clear-btn {
  top: 15px;
  right: 12px;
}

.ant-time-picker-panel-addon {
  padding: 0;
}

.ant-time-picker-panel-addon .header {
  font-size: 1.3em;
}

.ant-time-picker-panel-addon .header > span {
  width: 50%;
  text-align: center;
  display: inline-block;
  border-left: 1px solid #e9e9e9;
  padding: 8px;
}

.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 100%;
  width: 100%;
}

.ant-time-picker-panel-input {
  padding: 7px;
  font-size: 1.3em;
}

.ant-time-picker-icon {
  right: 21px;
}

.ant-time-picker-panel-combobox {
  width: 200px;
}

.ant-time-picker-panel-select {
  width: 100px;
}

.ant-time-picker-panel-select li {
  padding: 7px 0 5px;
  font-size: 1.3em;
  text-align: center;
  width: 100%;
}

.btn-toolbar {
  margin-left: 0;
}

.btn-toolbar > .btn-group {
  margin-left: 0;
}

.dropdown-menu > li > a {
  padding: 10px 20px;
}

.padded-top-xs {
  padding-top: 10px;
}
.padded-top-large {
  padding-top: 28px;
}
.padded-top {
  padding-top: 14px;
}
.padded-bottom {
  padding-bottom: 14px;
}
.padded-bottom-large {
  padding-bottom: 22px;
}

p.bold,
span.bold {
  font-weight: 600;
}

p.tag-second {
  margin-bottom: 0 !important;
}

.hide-empty:empty {
  display: none !important;
}

button,
button:focus,
span.Tappable,
span.Tappable-inactive,
span.Tappable-active,
span.Tappable:focus {
  border: none;
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  -webkit-appearance: none;
}

.container {
  width: 100%;
  padding-bottom: 64px;
}

.white {
  color: white;
}

.kale {
  color: var(--color-text-default);
}

.darker-kale {
  color: var(--color-beige);
}

.linen {
  color: #e8e5e0;
}

.ivory {
  color: #f5f2ed;
}

.grey {
  color: #424242;
}

.selected,
.selected:focus {
  border: 2px solid var(--color-primary) !important;
}

.nav-tabs {
  display: flex;
  border-bottom: 1px solid transparent;
}

.nav-tabs > li {
  flex: 1;
  float: initial;
}

.nav-tabs > li > a {
  color: var(--color-text-primary);
  display: inline-block;
  text-transform: uppercase;
}

.nav-tabs > li > a > * {
  font-weight: 700;
}

.nav-tabs > li > a:hover,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  background-color: rgba(0, 0, 0, 0);
  color: var(--color-light);
}

.nav-tabs > li.active > a {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid transparent !important;
  color: var(--color-light);
}

.nav-tabs > li > a:hover {
  border: 1px solid rgba(0, 0, 0, 0);
}

.tablist {
  font-size: 0.9em;
}

p,
a,
button,
span.n-text,
span,
input,
textarea,
li,
select,
div {
  font-family: 'Work Sans', 'Times New Roman', serif;
  font-size: 1em;
}

a {
  color: var(--color-text-contrast);
  transition: color 0s ease;
}
a:hover,
a:focus {
  color: var(--color-text-contrast);
  text-decoration: none;
  outline: none;
  outline-offset: 0;
}

a.blue-link,
button.blue-link {
  color: var(--color-text-hyperlink) !important;
  font-size: 0.95em;
}

a.blue-link:hover,
a.blue-link:focus,
button.blue-link:hover,
button.blue-link:focus {
  text-decoration: underline !important;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  list-style: none;
}

.content-main {
  text-align: center;
  padding: 20px 25px 0;
  position: relative;
}
.content-main h3 {
  margin-top: 0;
  padding-bottom: 8px;
}
.content-main p:last-child {
  margin-bottom: 0;
}
.content-mobile {
  text-align: center;
  padding-top: 6px;
  max-width: 90%;
  margin: auto;
}

.alert-text {
  width: 100%;
  font-size: 1em;
  text-align: center;
  color: var(--color-text-error);
  margin-top: 13px;
  margin-bottom: 20px;
}
.alert-text .lnr {
  font-size: 1.4em;
  margin-right: 5px;
  position: relative;
  top: 2px;
}

input {
  border: 1px solid var(--color-secondary);
  border-radius: 60px;
  padding: 12px 20px;
  font-size: 1em;
  font-family: 'Work Sans';
  font-weight: normal;
  box-shadow: none;
  color: var(--color-text-grey);
}
input::placeholder,
input.ant-calendar-picker-input::placeholder {
  color: #9eb5a6;
  opacity: 1;
}
input.ant-calendar-picker-input::placeholder {
  font-family: 'Work Sans';
}
input:focus {
  outline: none;
  border: 2px solid var(--color-primary);
  box-shadow: none;
}
input.one-row {
  height: 44px;
}
input.bar-half {
  width: 30vw;
  max-width: 361px;
  margin: auto;
}
input.bar-full {
  width: 100%;
  box-sizing: border-box;
}

.btn {
  border-radius: 60px;
}
.btn-toolbar .btn-group label,
.btn-toolbar .btn-group label:hover .btn-toolbar .btn-group label:active,
.btn-toolbar .btn-group label:active:hover {
  -webkit-appearance: initial;
  color: var(--color-text-primary);
  background-color: var(--color-background);
  cursor: pointer;
}
.btn-toolbar .btn-group label.active,
.btn-toolbar .btn-group label.active:hover,
.btn-toolbar .btn-group label.active:active,
.btn-toolbar .btn-group label.active:focus,
.btn-toolbar .btn-group label:focus {
  -webkit-appearance: initial;
  border: solid 1px var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-text-contrast);
  box-shadow: initial;
  cursor: initial;
}

.addComma::after {
  content: ', ';
}

.addComma:last-child::after {
  content: '';
}

.header-image {
  position: relative;
  text-align: center;
  z-index: -100;
}

.center,
.center-button {
  margin: 20px auto;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 27px;
}

.switch input {
  display: none;
}

input:checked + .slider {
  background-color: var(--color-light);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--color-primary);
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dedede;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 21px;
  width: 21px;
  left: 3px;
  bottom: 3px;
  background-color: var(--color-text-contrast);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.round {
  border-radius: 27px;
}

.slider.round:before {
  border-radius: 50%;
}

.panel-default {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.panel-default > .panel-heading {
  border: none;
}

.panel-default > .panel-heading > .panel-title > a {
  color: #000000;
}

/*--- Desktop only ---*/
@media (min-width: 993px) {
  body,
  .iconBackground {
    background-image: none;
  }

  .CalafiaBigTitle.MuiTypography-root {
    margin-bottom: -32px;
    z-index: 10;
  }
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .padded-top {
    padding-top: 22px;
  }
  .padded-bottom {
    padding-bottom: 16px;
  }
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  p.tag-second {
    margin-bottom: 5px !important;
  }
}

/* Mobile Landscape and Portrait */
@media (max-width: 767px) {
  p.tag-second {
    margin-bottom: 0 !important;
    padding-bottom: 0;
  }
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .center-button {
    margin: 0px auto;
  }
}

.ant-time-picker-input {
  padding: 22px 12px !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid var(--color-secondary) !important;
  border-radius: 16px;
  box-shadow: none;
  color: var(--color-primary);
  text-align: left;
  font-size: 14px;
}

.ant-time-picker-panel {
  z-index: 9999999999;
}

input[type='checkbox'] {
  visibility: hidden;
}

.checkboxCustom {
  width: 25px;
  margin: 0px;
  position: relative;
}
.checkboxCustom label {
  cursor: pointer;
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  top: 0;
  left: 0;
  background: transparent;
  border: 2px solid #90b698;
}
.checkboxCustom label:after {
  opacity: 0;
  content: '';
  position: absolute;
  width: 12px;
  height: 7px;
  background: transparent;
  top: 5px;
  left: 5px;
  border: 3px solid #90b698;
  border-top: none;
  border-right: none;

  transform: rotate(-45deg);
}
/**
* Create the hover event of the tick
*/
.checkboxCustom label:hover::after {
  opacity: 0.5;
}

/**
* Create the checkbox state for the tick
*/
.checkboxCustom input[type='checkbox']:checked + label:after {
  opacity: 1;
  border: 3px solid #f8f3ef;
  border-top: none;
  border-right: none;
}
.checkboxCustom input[type='checkbox']:checked + label {
  background: #90b698;
}

/* Mobile Landscape Only*/
@media (max-width: 767px) and (min-width: 480px) {
  .center {
    margin: 10px auto;
  }
}

/* Mobile Portrait Only */
@media (max-width: 479px) {
  .center {
    margin: 15px auto;
  }
}
