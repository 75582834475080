.CartMinOrderBanner {
  display: flex;
  flex-direction: column;
  color: var(--color-text-grey);
  border-radius: 16px;
  background-color: var(--color-secondary);
  overflow: hidden;
}

.CartMinOrderBanner > div {
  display: flex;
  align-items: center;
}

.CartMinOrderBannerRow {
  padding: 16px 24px;
}

.CartMinOrderBanner .fa-star {
  margin-top: 2px;
  margin-right: 4px;
}

.CartMinOrderBanner .BannerLabel {
  display: flex;
  flex: 1;
}

.CartMinOrderBanner .BannerLabel .Currency {
  font-weight: 600;
}

.CartMinOrderBanner .driverIcon {
  height: 20px;
}

.CartMinOrderBanner .ProgressBarRow {
  display: flex;
}

.CartMinOrderBanner .ProgressBarBackground,
.CartMinOrderBanner .ProgressBar {
  height: 15px;
  border-radius: 0 16px 16px 0;
}

.CartMinOrderBanner .ProgressBarBackground:last-of-type,
.CartMinOrderBanner .ProgressBarBackground:last-of-type .ProgressBar {
  border-radius: 0px;
}

.CartMinOrderBanner .ProgressBarBackground + .ProgressBarBackground {
  margin-left: 8px;
  border-radius: 16px 0 0 16px;
}

.CartMinOrderUnlockedBanner {
  display: flex;
  margin-top: 8px;
  padding-right: 1px;
}

.CartMinOrderUnlockedBanner .BannerLabel {
  flex: 1;
  text-align: left;
}

.CartMinOrderUnlockedBanner .fa-check-circle {
  margin-top: 2px;
  font-size: 1.2em;
}

.CartDriverBubble {
  position: relative;
  background-color: var(--color-primary);
  padding: 6px;
  border-radius: 50%;
  z-index: 1;
}

.CartDriverBubble.DriverBubbleBottomRight::after {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid var(--color-primary);
  border-top: 10px solid var(--color-primary);
  border-bottom: 10px solid transparent;
  right: 0px;
  bottom: -4px;
  z-index: -1;
}

.CartMinOrderBannerWrapper {
  position: relative;
}

.CartMinOrderProgressIcon {
  position: absolute;
  bottom: -5px;
}

.CartMinOrderUnlockedBanner {
  display: flex;
  color: var(--color-text-grey);
  border-radius: 16px;
  background-color: var(--color-secondary);
  overflow: hidden;
  padding: 16px 24px;
}

.CartMinOrderUnlockedBanner .fa {
  color: var(--color-text-primary);
}

.CartMinOrderBanner .ProgressBar {
  background-color: var(--color-primary);
}

@media (min-width: 768px) {
  .CartPaymentInfo .CartMinOrderBannerWrapper {
    display: none;
  }

  .Cart .CartMinOrderBannerWrapper {
    margin-right: 64px;
  }
}

@media (max-width: 767px) {
  .CartBodyLeft .CartMinOrderBannerWrapper {
    display: none;
  }

  .CartPaymentInfo .CartMinOrderBanner {
    margin-bottom: 20px;
  }

  .CartPaymentInfo .CartMinOrderUnlockedBanner {
    padding: 0px 0px 8px;
  }
}
