.Personalize .MenuDetailImage {
  background-color: #ffffff;
  width: 100%;
  flex: 1 1 auto;
  text-align: center;
  position: relative;
  overflow-x: hidden;
}

.Personalize .MenuDetailImage > img {
  object-fit: cover;
}

.Personalize {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  background-color: var(--color-background);
}

.PersonalizeForm .Select {
  color: var(--color-primary);
}

.Personalize .MenuContentFrame {
  padding-bottom: 8px;
  margin-bottom: 54px;
  padding-top: 24px;
}

.Personalize .MenuContentFrame .IngredientDescription {
  text-align: justify;
}

.PersonalizeInfo .IngredientsText {
  margin: 8px 0 0;
  line-height: 24px;
}

.Personalize .CustomizeLeft > div {
  flex: 1;
}

.Personalize .PersonalizeIngredientsAccordion {
  background-color: transparent;
  box-shadow: none;
}

.Personalize .PersonalizeIngredientsAccordion.Mui-disabled {
  background-color: transparent;
}

.Personalize .PersonalizeIngredientsAccordion .MuiAccordionSummary-root {
  min-height: auto;
  padding: 0;
  opacity: 1;
}

.Personalize .PersonalizeIngredientsAccordion .MuiAccordionSummary-content {
  margin: 0;
}

.Personalize .PersonalizeIngredientsAccordion .MuiAccordionDetails-root {
  padding: 0;
}

.Personalize .PersonalizeIngredientsAccordion .fa {
  color: var(--color-dark);
}

.Personalize .SectionSeparator {
  margin: 8px 24px 0;
}

.Personalize .SectionSeparator ul {
  justify-content: flex-start;
}

/*--- Desktop Only ---*/
@media (min-width: 993px) {
  .Personalize .MenuContentFrame {
    margin-bottom: 0px;
  }

  .Personalize .PersonalizeOrderItem .SectionSeparator .line {
    width: auto;
  }
  .Personalize .PersonalizeOrderItem .SectionSeparator .name,
  .Personalize .PersonalizeOrderItem .SectionSeparator ul li:first-child {
    flex: none;
  }
  .Personalize .PersonalizeOrderItem .SectionSeparator ul li:first-child .line {
    width: 16px;
  }

  .Personalize .PersonalizeInfo {
    margin-bottom: 24px;
  }
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .Personalize {
    flex-direction: column;
  }

  .Personalize .MenuDetailImage {
    flex: 1 0 auto;
  }

  .PersonalizeInfo {
    max-width: 600px;
    align-self: center;
  }
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px) {
  .PersonalizeInfo {
    max-width: 600px;
    align-self: center;
  }
}

/* Adapt for PersonalizeOrderItem max-width */
@media (max-width: 600px) {
  .Personalize .PersonalizeOrderItem .SectionSeparator .line {
    width: auto;
  }
  .Personalize .PersonalizeOrderItem .SectionSeparator .name,
  .Personalize .PersonalizeOrderItem .SectionSeparator ul li:first-child {
    flex: none;
  }
  .Personalize .PersonalizeOrderItem .SectionSeparator ul li:first-child .line {
    width: 16px;
  }
}

/*--- Tablet and Mobile Only ---*/
@media (max-width: 992px) {
  .Personalize {
    flex-direction: column;
  }

  .Personalize .CustomizeLeft {
    min-height: 160px;
    max-height: 160px;
  }

  .Personalize .CustomizeRight {
    overflow: initial;
    flex-direction: initial;
    flex: 1 0 auto;
  }

  .Personalize .MenuContentFrame {
    padding-top: 8px;
    padding-bottom: 54px;
  }
}

/* Mobile Portrait and Landscape */
@media (max-width: 992px) {
  .Personalize .CustomizeLeft .NotPremadeItem {
    flex-direction: column-reverse;
  }
  .Personalize .NutritionalBlock {
    position: fixed;
    display: none;
  }
  .Personalize .PersonalizeInfo {
    width: 100%;
    padding-left: 24px;
  }
}
