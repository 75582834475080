.LoadingButtonIcon {
  width: 100%;
  margin-top: 8px;
}

.LoadingButtonIcon .fa-spin {
  color: var(--color-primary);
  font-size: 1.2em;
}

/* Mobile Portrait Only */
@media (max-width: 479px) {
  .LoadingButtonIcon {
    text-align: center;
  }

  .LoadingButtonIcon .fa-spin {
    font-size: 1.4em;
  }
}
