.DietModal h5 {
  font-weight: 700;
}

.DietModal > .modal-dialog {
  width: 588px;
}

.DietModal .diet-support-list {
  width: 70%;
  margin: 24px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 360px;
}

.DietModal .btn-primary,
.DietModal .btn-secondary {
  width: 160px;
  margin: 8px;
}

.DietModal .DietModalButtonRow {
  display: flex;
  justify-content: center;
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px) {
  .DietModal > .modal-dialog {
    width: 90%;
    margin: auto;
  }

  .DietModal .diet-support-list {
    margin: 24px auto;
  }

  .DietModal .DietModalButtonRow {
    flex-direction: column-reverse;
  }

  .DietModal .btn-primary,
  .DietModal .btn-secondary {
    width: 100%;
    margin: 8px 0;
  }
}

/* Mobile Portrait Only*/
@media (max-width: 479px) {
  .DietModal .modal-body {
    padding: 24px 16px 8px;
  }

  .DietModal > .modal-dialog {
    width: 90%;
    margin: auto;
  }

  .DietModal .diet-support-list {
    width: 80%;
    margin: 24px auto;
  }

  .DietModal .DietModalButtonRow {
    flex-direction: column-reverse;
  }

  .DietModal .btn-primary,
  .DietModal .btn-secondary {
    width: 100%;
    margin: 8px 0;
  }
}
