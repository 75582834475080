.panel-heading ul {
  display: flex;
}

.panel-heading p {
  margin-bottom: 0;
}

.form-editable {
  width: 80%;
  max-width: calc(100% - 86px);
}

.form-editable p {
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 30px;
}

.Account .EditHeader {
  display: flex;
  flex-direction: column;
}

.QrCodeSection {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  padding-right: 160px;
  margin-bottom: 28px;
  align-items: center;
  min-width: 100%;
}

.QrCodeImage {
  height: auto;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .Account .EditHeader {
    flex-direction: column-reverse;
  }
  .QrCodeSection {
    padding-right: 0px;
    margin-top: 24px;
  }
}
