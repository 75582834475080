.PaymentMethod {
  padding-bottom: 8px;
}
.PaymentMethod ul {
  display: flex;
  justify-content: center;
  background-color: var(--color-secondary);
  border: none;
  align-items: center;
  box-shadow: none;
  padding: 24px;
  border-radius: 16px;
  width: 53%;
  color: var(--color-text-primary);
  height: 56px;
}
.PaymentMethod .trashButton {
  flex: 0;
}

.PaymentMethod .defaultText {
  flex: 0;
  justify-content: flex-end;
  font-size: 1em;
  color: var(--color-text-primary);
  padding-right: 16px;
}

.cc-list .setDefaultPaymentMethodButton {
  flex: 0 0 auto;
  justify-content: flex-end;
  font-size: 1em;
  color: var(--color-text-hyperlink);
  padding-right: 16px;
}
.cc-list .setDefaultPaymentMethodButton > button > span {
  justify-content: flex-start !important;
}
.cc-list .paymentMethodLabel {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  color: var(--color-text-grey);
  text-transform: uppercase;
  font-weight: 600;
}

@media (max-width: 992px) {
  .PaymentMethod > ul {
    width: 100%;
  }
}
