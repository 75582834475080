.IngredientAddWrapper {
  display: flex;
  flex-direction: column;
  width: 160px;
  min-height: 150px;
}

.IngredientAdd {
  margin-bottom: 10px;
  border: none;
  border-radius: 16px;
  background: transparent;
  text-align: center;
  height: 136px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.IngredientAdd:hover,
.IngredientAdd:focus {
  background-color: none;
  border: none;
}

.IngredientAdd img {
  width: 96px;
}

.IngredientAddCaption {
  font-size: 0.7em;
  width: 40px;
  margin: auto;
  color: var(--color-text-primary);
  line-height: 1.5em;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) and (min-width: 480px) {
  .IngredientAddWrapper {
    width: 120px;
    min-height: 170px;
  }
}

/*--- Mobile Portrait ---*/
@media (max-width: 479px) {
  .IngredientAddWrapper {
    width: 30%;
  }
}
