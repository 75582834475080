.DummyMarker {
  z-index: 1000;
  position: absolute;
  height: 41px;
  width: 57px;
}

.DummyMarkerIcon {
  z-index: 1001;
  position: absolute;
  bottom: 21px;
  height: 41px;
  left: 16px;
  width: 25px;
}

.DummyMarkerShadow {
  z-index: 1000;
  position: absolute;
  bottom: 21px;
  height: 41px;
  left: 16px;
  width: 41px;
}
