.AppUpdateBanner {
  position: fixed;
  z-index: 10000;
  display: flex;
  width: max-content;
}

.AppUpdateBanner .AppUpdatePopUp {
  border-radius: 16px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
  margin: 0 auto 24px;
  background-color: var(--color-content);
  padding: 16px 24px;
  text-align: center;
  display: flex;
  flex: 0 1 auto;
}

.AppUpdateBanner .AppUpdateInnerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.AppUpdateBanner .AppUpdateLeftContainer {
  text-align: left;
  flex-direction: column;
}

.AppUpdateBanner .AppUpdateRightButton {
  margin-left: 32px;
}

/* Desktop */
@media (min-width: 768px) {
  .AppUpdateBanner {
    top: 96px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .AppUpdateBanner .AppUpdateLeftContainer * {
    display: inline;
  }
  .AppUpdateBanner .AppUpdateLeftContainer * + * {
    margin-left: 0.25em;
  }
}

/* Mobile Portrait */
@media (max-width: 767px) {
  .AppUpdateBanner {
    bottom: 16px;
    width: 100%;
    padding: 0 24px;
  }

  .AppUpdateBanner .AppUpdatePopUp {
    margin-bottom: 0;
    width: 100%;
  }
}

/* Mobile Landscape */
@media (max-width: 500px) {
  .AppUpdateBanner .AppUpdateInnerContainer {
    flex-direction: column;
    justify-content: center;
  }
  .AppUpdateBanner .AppUpdateLeftContainer * {
    text-align: center;
  }
  .AppUpdateBanner .AppUpdateRightButton {
    margin-left: 0px;
    margin-top: 16px;
  }
}
