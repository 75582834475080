.DietModalButton {
  display: inline-flex;
  flex-basis: 100%;
  margin: 0 0;
  align-items: center;
  border-radius: 16px;
  height: 52px;
  padding: 0;
  padding-left: 24px;
}

.DietModalButton,
.DietModalButton:focus {
  background-color: none;
  border: none;
}

.DietModalButton.selected {
  border: none !important;
}

.DietModalButton img {
  flex: 0 0 auto;
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.DietModal .DietModalButton {
  flex-basis: 50%;
}

@media (max-width: 767px) {
  .DietModalButton {
    padding: 0 16px 0 0;
  }
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px) {
  .DietModal .DietModalButton {
    font-size: 0.9em;
  }

  .DietModalButton img {
    width: 20%;
    margin-right: 4%;
    margin-top: -5px;
  }
}

/* Mobile Portrait Only*/
@media (max-width: 479px) {
  .DietModal .DietModalButton {
    flex-basis: 100%;
    font-size: 0.9em;
    padding-left: 0;
  }

  .DietModalButton img {
    width: 20%;
    margin-right: 4%;
  }
}
