.EmailAddress {
  background: url(../../images/icon-envelope-dark.svg) no-repeat 22px 16px;
  background-size: 17px;
  padding-left: 52px !important;
}

.EmailAddressLight {
  background: url(../../images/icon-envelope.svg) no-repeat 22px 16px;
  background-size: 17px;
  padding-left: 52px !important;
}
