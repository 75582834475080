.ErrorText {
  color: var(--color-text-error);
}

.PartnerEmailLabel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--color-primary);
  border-radius: 60px;
  height: 52px;
  padding: 0 20px !important;
  font-size: 1em;
  background-color: transparent;
  color: var(--color-text-grey);
  box-shadow: none;
}

.PartnerEmailLabel:focus-within,
.PartnerEmailLabel.isChanged {
  border-width: 2px;
  background-color: white;
}

.PartnerEmailLabel.isError {
  color: var(--color-text-error);
  border-color: var(--color-text-error);
  border-width: 1px;
}

.PartnerEmailLabel input {
  flex: 1;
  padding: 0;
  border: none;
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: inherit;
}

.PartnerEmailLabel input:focus {
  border: none;
  background-color: transparent;
}

.PartnerEmailLabel .Badge {
  margin-right: 0px;
}
