.SignInWrapper {
  flex: 1;
  overflow: auto;
}

.SignIn input {
  padding: 12px 20px;
  background-color: transparent;
  border: 1px solid var(--color-primary);
  border-radius: 60px;
  box-shadow: none;
  color: var(--color-text-grey);
  font-size: 1em;
  text-align: left;
  width: 100%;
  margin-bottom: 16px;
}

.SignIn input:focus {
  border: 2px solid var(--color-primary) !important;
}

.SignIn .input-alert input {
  border: 1px solid var(--color-text-error) !important;
  background-color: #fbece9 !important;
}

.SignIn .input-alert p.input-label {
  color: var(--color-text-error);
}

.SignIn ::-webkit-input-placeholder {
  text-align: left;
  color: #9eb5a6;
  position: relative;
  left: 0px;
}

.SignIn ::-ms-input-placeholder {
  text-align: left;
  color: #9eb5a6;
  position: relative;
  left: 0px;
}

.SignIn ::-moz-placeholder {
  text-align: left;
  color: #9eb5a6;
  position: relative;
  left: 0px;
}

.SignIn input::placeholder {
  opacity: 1;
}

.SignIn {
  text-align: center;
  margin: 32px auto;
  width: 470px;
  color: var(--color-text-grey);
  margin-top: 32px;
  background-color: var(--color-background);
}
.SignIn h4 {
  margin-bottom: 20px;
}
.SignIn > p {
  width: 70%;
  margin: auto;
  margin-bottom: 15px;
}
.SignIn .longer-text {
  width: 150%;
  margin: 0 auto 35px -25%;
}
.SignIn .middle-text {
  width: 100%;
  margin: 24px auto 30px auto;
  line-height: 1.57;
}

.SignIn .SignInCopy {
  margin-top: 8px;
  margin-bottom: 16px;
}

.SignIn p strong {
  font-weight: 600;
}
.SignIn p .blue-link span {
  font-weight: normal;
}
.SignIn .input-alert-text {
  color: var(--color-text-error);
  margin-bottom: 20px;
  text-align: left;
  margin-left: 8px;
}
.SignIn .input-alert-text span {
  font-size: 1.4em;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.SignIn .input-alert-text {
  color: var(--color-text-error);
  margin-bottom: 20px;
}
.SignIn .input-alert-text span {
  font-size: 1.4em;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.SignIn img:not(.PaymentIcon, .PaymentFailureAlertIcon) {
  width: 50%;
}
.SignIn p.input-label {
  font-size: 0.95em;
  text-align: left;
  width: 100%;
  margin-bottom: 8px;
}
.SignIn .btn-primary {
  width: 100%;
  height: 54px;
}

h5.user-email {
  margin-bottom: 16px;
}

.user-email::before {
  background: url(../../images/icon-envelope-dark.svg) no-repeat 60% 70%;
  background-size: 50%;
  padding-left: 34px !important;
  content: '';
  margin-left: -16px;
  margin-right: 8px;
}
.SignIn ul.name {
  position: relative;
  padding-bottom: 85px;
  margin-top: 16px;
}
.SignIn ul.name li {
  display: inline-block;
  width: 49%;
}
.SignIn ul.name li:first-child {
  position: absolute;
  left: 0;
}
.SignIn ul.name li:last-child {
  position: absolute;
  right: 0;
}

.SignIn ul.payment-icon li {
  display: inline-block;
  margin: 12px 6px 8px 6px;
}
.SignIn ul.payment-icon li img {
  width: 34px;
  height: auto;
}
.SignIn ul.save-card-check {
  margin: 25px auto;
  text-align: center;
}
.SignIn ul.save-card-check li {
  display: inline-block;
}
.SignIn ul.save-card-check li:nth-child(2) {
  padding-left: 5px;
  position: relative;
  top: 3px;
}
.SignIn .btn-short {
  max-width: 300px;
}

.SignInPage {
  background-color: var(--color-background);
}

.SignIn .EmailLabel {
  text-align: left;
  font-weight: bold;
  margin-left: 8px;
}

.SignIn .Button.blue-link {
  color: var(--color-text-primary) !important;
}

.SignIn .SignInTitle {
  margin-bottom: 8px;
  text-transform: capitalize;
}

.SignIn .SignInTitleTop {
  margin-bottom: -8px;
}

.SignIn .Register .SignInTitle {
  margin-bottom: 24px;
  text-transform: capitalize;
}

.SignIn .PasswordInput {
  margin-bottom: 16px;
}

.SignIn .PasswordInput input {
  margin-bottom: 0;
}

.SignIn .PasswordInput {
  margin-bottom: 16px;
}

.SignIn .PasswordInput input {
  margin-bottom: 0;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .SignIn {
    margin-top: 24px;
    width: 86%;
    max-width: 380px;
  }
  .SignIn h5.user-email {
    text-align: left;
    width: 100%;
    padding-left: 8px;
    padding-bottom: 0px;
  }
}
