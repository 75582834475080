.linkText {
  color: #ef6700;
  font-weight: bold;
}

.FacebookResetPage .input-alert {
  border: 1px solid var(--color-text-error) !important;
  background-color: #feecec !important;
}

.FacebookResetHeading {
  font-size: 4em !important;
}

@media (max-width: 992px) {
  .FacebookResetHeading {
    font-size: 3.5em !important;
  }
}
