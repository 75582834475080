.MarketingOptIn .Checkbox {
  margin-bottom: 22px;
}

.MarketingOptIn .Checkbox label {
  text-align: left;
  font-weight: normal;
  align-items: flex-start;
}

/* Checkmark */
.MarketingOptIn .Checkbox label::after {
  color: transparent;
}

/* Checkmark on the checked state*/
.MarketingOptIn .Checkbox input[type='checkbox']:checked + label::after {
  color: var(--color-text-contrast);
  margin-top: 6px;
}

/* Checkbox on checked state */
.MarketingOptIn .Checkbox input[type='checkbox']:checked + label::before {
  background-color: var(--color-secondary);
}
