.UserSpinnerModal .SaladAnimation {
  width: 240px;
  height: auto;
  margin-top: 0px;
}

.UserSpinnerModal.modal.modal-small .modal-content {
  width: 360px;
}

.UserSpinnerModal .modal-body {
  padding: 48px 32px 40px;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .UserSpinnerModal.modal.modal-small .modal-content {
    width: 300px;
  }

  .UserSpinnerModal.modal-small .modal-body {
    padding: 40px 24px 32px;
  }
}
