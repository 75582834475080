.OrderProgressBar {
  position: relative;
  margin: 24px auto 24px;
  margin-bottom: 8px !important;
  width: 80%;
}
.OrderProgressBar .StepTickRow {
  position: relative;
  margin: 0 auto;
  width: calc(80% + 28px);
}
.OrderProgressBar .StepTicks {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: auto;
  z-index: 1;
}
.OrderProgressBar .StepTicks .StepTick {
  width: 28px;
  height: 28px;
  background-color: var(--color-panel-secondary);
  color: var(--color-text-primary);
  border-radius: 14px;
  padding: 5px;
  text-align: center;
  box-shadow: none;
  margin-bottom: 12px;
  position: relative;
  font-weight: 600;
}
.OrderProgressBar .StepTicks .StepTick.marked::after {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  content: url(./icon-ticked.svg);
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}
.OrderProgressBar .StepBar {
  height: 8px;
  width: calc(100% - 28px);
  margin: 10px 14px;
  position: absolute;
  top: 0;
  left: 0;
}
.OrderProgressBar .StepBar .StepBarBg {
  width: 100%;
  height: 100%;
  background-color: var(--color-panel-secondary);
  position: absolute;
}
.OrderProgressBar .StepBar .BarProgress-1 {
  width: 26%;
  height: 100%;
  background-color: var(--color-primary);
  position: absolute;
  top: 0;
  left: 0;
}
.OrderProgressBar .StepBar .BarProgress-2 {
  width: 75%;
  height: 100%;
  background-color: var(--color-primary);
  position: absolute;
  top: 0;
  left: 0;
}
.OrderProgressBar .StepBar .BarProgress-3 {
  width: 100%;
  height: 100%;
  background-color: var(--color-primary);
  position: absolute;
  top: 0;
  left: 0;
}
.OrderProgressBar .Steps {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: auto;
  z-index: 1;
}
.OrderProgressBar .Steps .Step {
  text-align: center;
  width: 20%;
  color: var(--color-text-grey);
}
