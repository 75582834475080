.Menu {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.Menu .MenuListing {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.Menu .MenuListingHeader {
  padding: 24px 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Menu .SelectedRewardWrapper {
  max-width: 600px;
  margin: 16px auto 0;
}

.Menu .LoyaltyRewardsList {
  margin-top: 10px;
}

.Menu .MenuContentWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
  overflow: auto;
  flex: 4;
}

.Menu .LoyaltyRewardWrapper {
  max-width: 600px;
}

.section {
  clear: both;
  text-align: center;
  margin-bottom: 10px;
}

.Menu .MenuListingHeader .DiningChoiceChangeButton {
  font-size: 0.83em;
  position: absolute;
  left: 100%;
  top: -6px;
}

.Menu .MenuContentWrapper .TitledMenuSection .SectionSeparator .name .MuiTypography-root {
  color: var(--color-dark);
  text-transform: uppercase;
  font-family: 'Sharp Grotesk SmBold15';
  font-weight: 400;
  letter-spacing: 0.05em;
  font-size: 1.3em;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .Menu .MenuListing {
    flex-direction: column;
  }

  .Menu .MenuListingHeader {
    display: none;
  }

  .Menu .MenuContentWrapper {
    padding: 8px 16px 40px;
  }

  .MenuContentWrapper > div > .category-name {
    padding: 8px 8px 0;
  }

  .MenuContentWrapper > div.section + .section > .category-name {
    padding-top: 20px;
  }

  .MenuContentWrapper > div > .category-desc,
  .MenuContentWrapper > div > .SectionSeparator {
    display: none;
  }

  .MenuContentWrapper > .section {
    margin-bottom: 0;
  }

  .MenuContentWrapper .DietButtonWrapper {
    margin-bottom: 8px;
  }
}
