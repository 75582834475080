.DesktopTooltipPopper .DesktopTooltip {
  background-color: var(--color-background);
  font-size: 1em;
  box-shadow: 2px 2px 4px rgba(54, 53, 52, 0.1);
  border-radius: 8px;
  margin: 4px 16px !important;
  text-align: center;
  padding: 16px 24px;
  max-width: 600px;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .DesktopTooltipPopper .DesktopTooltip {
    display: none;
  }

  .DesktopTooltipInnerWrapper {
    display: contents;
  }
}
