.HighlightedRewardModal.modal .modal-control {
  position: absolute;
}

.HighlightedRewardModal.modal .modal-content {
  overflow: hidden;
  border-radius: 16px;
}

.HighlightedRewardModal .RewardImage {
  height: 200px;
  max-width: 100%;
  background-color: #ffffff;
  text-align: center;
}

.HighlightedRewardModal .RewardImage img {
  height: 200px;
  max-width: 100%;
}

.HighlightedRewardModal.modal .modal-dialog {
  width: 100% !important;
  max-width: none !important;
  padding: 0 27.5px;
}

.HighlightedRewardModal.modal .modal-body {
  text-align: left;
  padding: 0;
}

.HighlightedRewardModal .HighlightedRewardModalSection {
  padding: 15px;
}

.HighlightedRewardModal .RewardInfo .HighlightedRewardModalSection {
  background-color: none;
  color: var(--color-dark);
  padding: 24px 16px 8px;
  text-align: center;
}

.HighlightedRewardModal .panel-heading,
.HighlightedRewardModal .panel-body {
  padding: 0;
}

.HighlightedRewardModal .panel-body {
  padding-top: 15px;
}

.HighlightedRewardModal .panel-default {
  margin-bottom: 0;
}

.HighlightedRewardModal .panel-default > .panel-heading > .panel-title > a {
  color: var(--color-primary);
}

.HighlightedRewardModal .RewardInfo .RewardTitle {
  text-align: center;
  font-size: 1.5em;
  font-weight: 800;
}

.HighlightedRewardModal .RedeemSection {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.HighlightedRewardModal .RedeemSection .RewardDescription {
  white-space: pre-wrap;
  padding: 8px 32px 8px;
  margin-bottom: 24px;
  width: 100%;
  max-height: 50vh;
  overflow: auto;
}

.HighlightedRewardModal .RedeemSection .btn-secondary {
  font-size: 1em;
}

.HighlightedRewardModal .RedeemSection .ClaimNumber {
  margin: 20px;
}

.HighlightedRewardModal .RedeemSection .PromptScanSection {
  margin-left: 24px;
  margin-bottom: 16px;
  margin-top: 4px;
  text-align: left;
}

.HighlightedRewardModal .RedeemSection .PromptScanSection ol {
  list-style-type: decimal;
}

.HighlightedRewardModal .RedeemSection .PromptScanSection ol li::marker {
  font-weight: normal;
}

.HighlightedRewardModal .blue-link {
  text-decoration: none;
}

.HighlightedRewardModal .Button {
  margin-bottom: 8px;
  width: 70%;
}

.HighlightedRewardModal .btn-secondary {
  padding: 12px 50px;
}

.HighlightedRewardModal.flying {
  position: fixed;
  animation: flyingToCart 0.5s 1 ease-out;
}

@keyframes flyingToCart {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0.7;
  }
  100% {
    top: -50%;
    left: calc(100vw - 50% - 64px);
    opacity: 0;
    scale: 0.1;
  }
}

/*--- Tablet and Desktop ---*/
@media (min-width: 768px) {
  .HighlightedRewardModal.modal .modal-body {
    display: flex;
    flex-direction: column;
  }

  .HighlightedRewardModal.modal .modal-dialog {
    width: 80% !important;
    max-width: 778px !important;
  }

  .HighlightedRewardModal .RewardImage,
  .HighlightedRewardModal .RewardImage img {
    height: initial;
    width: 350px;
    flex: 1;
    max-width: initial;
  }

  .HighlightedRewardModal .RewardInfo {
    display: flex;
    flex-direction: column;
  }

  .HighlightedRewardModal .RedeemSection {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
}

/*--- Mobile Landscape and Portrait---*/
@media (max-width: 768px) {
  .HighlightedRewardModal.modal .modal-body {
    padding: 0 8px;
  }

  .HighlightedRewardModal .RedeemSection .RewardDescription {
    padding: 0 8px;
  }

  .HighlightedRewardModal .RedeemSection .Button {
    width: 100%;
  }

  @keyframes flyingToCart {
    0% {
      opacity: 1;
    }
    80% {
      opacity: 0.7;
    }
    100% {
      top: -100%;
      left: calc(100vw - 50% - 32px);
      opacity: 0;
      scale: 0.1;
    }
  }
}
