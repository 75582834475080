.DietTag {
  display: inline-flex;
  padding: 11px 16px;
  margin: 0px 8px 8px 0;
  border-radius: 60px;
  background-color: var(--color-dark);
  color: var(--color-text-contrast);
  font-size: 0.9em;
  text-transform: lowercase;
}

.DietTag.dietTags {
  background-color: var(--color-beige);
}

.DietTag > span {
  white-space: nowrap;
}

.DietTag > span::first-letter {
  text-transform: capitalize;
}

.DietTag .Button {
  margin-left: 8px;
}

/* Mobile Portrait and Landscape */
@media (max-width: 767px) {
  .DietButtonVariantToggle .DietTag {
    padding: 4px 8px;
  }
}
