.DiningChoiceModal .modal-content {
  background: transparent;
  box-shadow: none !important;
}

.DiningChoiceModal .modal-body {
  flex: 1;
  background: transparent;
}

.DiningChoiceModal .modal-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.DiningChoiceModal .modal-content .modal-control {
  position: unset;
}

.DiningChoiceModal .modal-content .modal-body {
  padding: 0;
}

.DiningChoiceModal .btn-close-modal {
  background-image: url(./btn-close-modal.svg);
}

.DiningChoiceModal {
  background: rgba(47, 121, 58, 0.4);
}
