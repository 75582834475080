.BackButton .BackButtonMobile .fa {
  font-size: 1.3em;
}

/*--- Desktop only ---*/
@media (min-width: 993px) {
  .BackButton.forceBackButtonShow {
    display: initial !important;
  }
}
