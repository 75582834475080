.GuestLimitModal .Button {
  margin-top: 30px;
  justify-content: center;
}

.GuestLimitModal .modal-body {
  padding: 0 35px 40px 35px;
}

.GuestLimitModal .guest-limit-title {
  padding-bottom: 50px;
}
