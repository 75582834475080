.FacebookConnectedLogo {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #3b5998;
  color: white;
  margin-bottom: 8px;
}

.FacebookConnectedLogo .fa {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 26px;
  flex: 1;
}

.facebook-text {
  flex: 5;
}

.glyphicon-ok {
  flex: 1;
  font-size: 1.1em;
  color: white;
}
