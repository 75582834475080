body {
  position: fixed;
  transition: transform 0.5s ease-out;
}

body .MenuOverlay {
  position: fixed;
  background: #2f2f2f;
  backdrop-filter: blur(2px);
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity 0.5s ease-out;
}

body.isMobileMenuClosed {
  position: initial;
  overflow: hidden;
}

body.isMobileMenuClosed .MenuOverlay {
  position: initial;
}

.MenuMobile {
  position: absolute;
  top: 0;
  left: -76vw;
  background-color: var(--color-modal-primary);
  width: 76vw;
  min-height: 100vh;
  padding: 16px 0;
  text-align: left;
}

.MenuMobile ul li {
  color: var(--color-text-primary);
  font-size: 1.1em;
  line-height: 2.4em;
  padding-left: 22px;
  padding-right: 22px;
}

.MenuMobile ul li.section-label {
  color: var(--color-text-primary) !important;
  line-height: 2.2em !important;
  padding-top: 10px;
  border-top: none;
}

.MenuMobile ul {
  margin-bottom: 12x;
  padding-bottom: 8px;
}

.MenuMobile ul.menu-last-chunk {
  border-bottom: none;
}

.MenuMobile .MenuMobileLogoRow {
  padding-top: 8px;
}

.MenuMobileLogoRow a {
  margin-right: 16px;
}

.MenuMobile .Button.blue-link {
  font-size: 14px;
  font-weight: 400;
}

.MenuMobile .MenuMobileCloseButton {
  position: absolute;
  right: 24px;
  top: 24px;
  color: #1f423c;
  font-size: 20px;
}

.MenuMobile .MenuMobileName {
  padding-top: 8px;
}

.MenuMobile .menu-mobile-links li .Button > span,
.MenuMobile .menu-last-chunk li .Button > span {
  font-weight: 400;
}

.MenuMobile .menu-mobile-links li a,
.MenuMobile .menu-last-chunk li a {
  color: var(--color-text-primary);
}

.MenuMobile .menu-mobile-links .MenuMobileHomeButton.isHomePage > span,
.MenuMobile .menu-mobile-links li a.active {
  font-weight: 700;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  body.isMobileMenuOpen {
    transform: translateX(76vw);
    overflow: hidden;
  }

  body.isMobileMenuOpen .MenuOverlay {
    opacity: 0.4;
    z-index: 100;
  }
}
