.DietButton {
  font-size: 0.85em;
  display: block;
  width: 64px;
  height: 64px;
  top: auto;
  bottom: 15px;
  text-align: center;
  background-color: var(--color-primary);
  color: var(--color-text-contrast);
  right: 12px;
  border-radius: 32px;
  padding: 18px 0;
  z-index: 9999;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.DietButton button span {
  display: block;
  position: absolute;
  bottom: 10px;
  left: 28px;
}

.DietSelectButton {
  margin-right: 16px;
  min-width: 120px;
  margin-bottom: 8px;
}

.DietSelectButton .DietButtonInputLabel {
  font-size: 15px;
  font-weight: 600;
  color: var(--color-text-primary);
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.DietSelectButton .DietButtonInputLabel svg {
  margin-right: 4px;
}

.DietSelectButton .DietButtonInputLabel > div {
  flex: 1;
  text-align: center;
}

.DietSelectButton .MuiSelect-select {
  padding: 8px 32px 8px 24px;
}

.DietSelectButton.isSelectOpen .MuiSelect-select,
.DietButtonWrapper .DietSelectButton:hover .MuiSelect-select {
  border-radius: 60px;
  background-color: white;
}

.DietSelectButton .MuiOutlinedInput-notchedOutline {
  border-radius: 60px;
  border: 1px solid var(--color-primary);
}

.DietButtonWrapper .DietSelectButton.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
}

.DietButtonWrapper .DietSelectButton.isSelectOpen .MuiOutlinedInput-notchedOutline,
.DietButtonWrapper .DietSelectButton:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--color-primary);
}

.DietSelectButton .MuiSelect-icon {
  right: 24px;
  color: var(--color-text-primary);
}

.DietButtonVariantToggle {
  margin: 8px 20px;
  padding: 16px 24px 8px;
  border-radius: 16px;
  background: var(--color-secondary);
}

.DietButtonVariantToggle .DietToggleTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.DietButtonVariantToggle .DietButtonToggle {
  display: flex;
  align-items: center;
}

.DietButtonVariantToggle .DietButtonToggle .switch {
  margin-bottom: 0;
}

.DietButtonVariantToggle .DietTag {
  padding: 8px 16px;
}

.DietButtonWrapper .DietResetButton {
  padding: 10px 24px 10px 24px;
  min-width: 120px;
  margin-bottom: 8px;
}

/* Mobile Portrait and Landscape */
@media (max-width: 767px) {
  .DietButtonVariantToggle .DietButtonToggle .switch {
    scale: 0.8;
  }
  .DietSelectButton {
    margin-right: 8px;
  }
  .DietSelectButton .MuiSelect-select {
    padding: 6px 24px 6px 12px;
  }
  .DietSelectButton .MuiSelect-icon {
    right: 12px;
  }
  .DietSelectButton .DietButtonInputLabel {
    margin-right: 1px;
    font-size: 14px;
  }
  .DietButtonWrapper .DietResetButton {
    padding: 8px 24px 8px 24px;
    font-size: 14px;
  }
}
