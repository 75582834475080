.ScanSuccessModal .Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ScanSuccessModal .Title,
.ScanSuccessModal .Text {
  padding-bottom: 16px;
}

.ScanSuccessModal .IconLoyalty {
  transform: rotate(-12deg);
  align-self: center;
}

.ScanSuccessModal .Button {
  border-width: 2px;
  border-radius: 60px;
}

.ScanSuccessModal .ErrorSticker {
  background-color: transparent;
  color: none;
  padding: 0;
  box-shadow: none;
  color: var(--color-text-grey);
  border: none;
}
