.IngredientsSection {
  text-align: left;
  padding: 2px 20px;
  margin-bottom: 8px;
}
.IngredientsSection h5 {
  font-size: 1.1em;
}
.IngredientsSection h5 span {
  margin-left: 5px;
  font-size: 0.75em;
  position: relative;
  top: -2px;
}

.IngredientsSectionName {
  display: inline-block;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.Description {
  margin-top: 0;
  margin-bottom: 0px;
  clear: both;
  font-size: 0.85em;
}

.IngredientsSectionHeader .Description {
  margin-left: 16px;
  display: flex;
  align-items: center;
}

.IngredientsList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.SectionToggle {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 0px;
  padding-left: 16px;
  white-space: nowrap;
  padding-top: 1px;
}

.SectionToggle .switch {
  vertical-align: middle;
  margin-left: 10px;
  margin-bottom: 0;
}

/*--- Tablet & Mobile ---*/
@media (max-width: 992px) {
  .IngredientsSection h5 {
    font-size: 1.3em;
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 600px) {
  .IngredientsSectionHeader {
    flex-wrap: wrap;
  }

  .IngredientsSectionHeader .Description {
    margin: 8px 0;
    order: 4;
  }
}
