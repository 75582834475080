.PaymentMethodContainer {
  display: flex;
}

.SelectPaymentMethod {
  display: flex;
  text-align: left;
  margin: 4px 0 8px;
  align-content: center;
  border: 1px solid var(--color-primary);
  width: 50%;
  border-radius: 60px;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  background-color: transparent;
  padding-right: 13px;
}

.SelectPaymentMethod img .hide {
  width: auto;
  height: 17px;
  margin-right: 10px;
  display: none;
}

.SelectPaymentMethod .ApplePayIcon {
  width: auto;
  height: 30px;
  margin-right: 5px;
}

.SelectPaymentMethod .CardIcon {
  color: var(--color-text-primary);
  margin-right: 6px;
}

.SelectPaymentMethod .GooglePayIcon {
  display: block;
  height: 45px;
  margin: 0;
}

.SelectPaymentMethod span {
  vertical-align: middle;
}

.SelectPaymentMethod .PaymentMethodList {
  width: 100%;
  letter-spacing: 0px;
  font-size: 0.85em;
  font-family: 'Work Sans';
  padding: 0;
  line-height: inherit;
  border: none;
  margin-right: -48px;
  background-color: transparent;
}

.SelectPaymentMethod .PaymentMethodList:hover {
  cursor: pointer;
}

.SelectPaymentMethod .PaymentMethodList:focus {
  outline: none;
}

.SelectPaymentMethod .SelectWrapper {
  flex: 1;
}

.SelectPaymentMethod .SelectWrapper::after {
  position: absolute;
  content: '\f0d7';
  color: var(--color-text-primary);
  font-size: 16px;
  font-family: 'FontAwesome', 'Work Sans';
  right: unset;
  right: 0;
  top: 12px;
}

.SelectPaymentMethod .PaymentMethodList.isPaymentMethodSelected {
  padding-right: 0px;
  letter-spacing: 3px;
}

.SelectPaymentMethod .Button {
  float: right;
}

.SelectPaymentMethod .PaymentMethodList.newPaymentMethod {
  padding-left: 0px;
  letter-spacing: normal;
}

.PaymentMethodContainer .DbsLogoWrapper {
  padding-left: 15px;
  height: 48px;
  margin: 4px 0 8px;
  position: relative;
}

.PaymentMethodContainer .DbsLogoWrapper .DbsLogo {
  width: auto;
  height: 100%;
  object-fit: contain;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .SelectPaymentMethod {
    margin: 8px 0;
  }
}
