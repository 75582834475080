.ErrorSticker {
  background-color: #ffd5d5;
  border-radius: 16px;
  color: var(--color-text-error);
  border: 1px solid var(--color-text-error);
  padding: 8px 16px;
  margin-bottom: 18px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
}
.ErrorSticker p {
  margin-bottom: 0;
}
.ErrorSticker p:nth-child(n + 2) {
  margin-top: 8px;
}
.ErrorSticker svg {
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
  top: 4px;
}
