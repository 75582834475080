.CartPriceLine {
  width: 100%;
  display: flex;
  margin-bottom: 2px;
}

.CartPriceLineTitle {
  display: flex;
  margin-bottom: 0px;
}

.CartPriceLineTitle .Button {
  display: inline-block;
  margin-left: 5px;
  color: #90b797;
}

.CartPriceLine li {
  display: inline-flex;
  vertical-align: text-top;
  padding: 0;
  margin: 0;
  height: auto;
}

.CartPriceLine li p.TotalPrice {
  margin-bottom: 0;
}

.CartPriceLine li:first-child {
  text-align: left;
  flex: 1;
}

.CartPriceLine li:last-child {
  text-align: right;
}

.CartPriceLine.CartTotal {
  margin-top: 4px;
  font-size: 1.5em;
}

.CartPriceLine.CartTotal .CartPriceLineTitle > .MuiTypography-root {
  font-weight: 700;
}

.CartPriceLine.CartTotal .TotalPrice {
  color: var(--color-text-grey);
}

.Tax {
  display: flex;
  color: var(--color-text-grey);
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .CartPriceLine.CartTotal {
    font-size: 1.2em;
  }
}

.Tax > .Currency {
  margin-left: 0.3em;
  margin-right: 0.15em;
}
