.PaymentMethods .ToastWrapper {
  top: 100px;
  width: 100%;
}

.AddIconLogo {
  margin-right: 5px;
}

.NotificationBox {
  display: flex;
  align-items: center;
  gap: 6px;
}
