.BrowserPage .TopBar {
  display: none;
}

.Browser {
  max-width: 670px;
  margin: 0 auto;
  text-align: center;
}

.Browser .Logo {
  height: 100px;
}

.Browser h4 {
  margin: 30px 0;
}

.Browser .BrowserPicker {
  margin-top: 50px;
}

.Browser .BrowserPicker img {
  width: 110px;
  height: auto;
  margin: 0 10px;
}
