.CartItemQuantitySelector {
  width: 80px;
  height: 40px;
}

.CartItemQuantitySelector .MuiSelect-select {
  padding-left: 20px;
  padding-right: 24px;
}

.CartItemQuantitySelector .MuiSelect-select[aria-expanded='true'] {
  color: var(--color-primary);
}

.CartItemQuantitySelector .MuiOutlinedInput-notchedOutline,
.CartItemQuantitySelector .MuiOutlinedInput-notchedOutline:hover {
  border: none;
}

.CartItemQuantitySelector .MuiSelect-select[aria-expanded='true'] .MuiTypography-root {
  font-weight: 600;
  color: #ccdb74;
}

.CartItemQuantitySelector .MuiSelect-icon {
  color: var(--color-text-grey);
}

.CartItemQuantitySelector .MuiSelect-select[aria-expanded='true'] ~ .MuiSelect-icon {
  color: #ccdb74;
}

.CartItemQuantitySelector .MuiSelect-iconOpen {
  color: var(--color-text-primary);
}

.CartItemQuantitySelectorPopOver {
  z-index: 99999999 !important;
}
