.CustomizeLeft {
  display: flex;
  flex-direction: column;
  flex: 5;
  overflow-y: auto;
}
.CustomizeLeft .NutritionalDisclaimer {
  padding-bottom: 60px;
}
.CustomizeLeft .MenuDetailImage {
  width: 100%;
  display: flex;
  text-align: center;
  overflow-x: hidden;
}

.CustomizeLeft .MenuDetailImage > img {
  object-fit: contain;
  object-position: center;
  flex: 1 1 auto;
  max-height: 100%;
  max-width: 100%;
}

.CustomizeLeft > div {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .CustomizeLeft {
    width: 100%;
    min-height: 30%;
    overflow: hidden;
  }
  .CustomizeLeft .MenuDetailImage {
    max-height: 100%;
    display: flex;
  }
  .CustomizeLeft .MenuDetailImage span {
    display: flex;
    flex: 1;
  }
  .CustomizeLeft .MenuDetailImage > img {
    max-height: 100%;
  }
}
