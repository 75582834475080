.LoyaltyRewardsList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoyaltyRewardsList .SectionSeparator {
  margin-top: 22px;
  width: 100%;
}

.LoyaltyRewardsList .LoyaltyRewardWrapper {
  width: 100%;
}

.LoyaltyRewardsList .SectionSeparator li.name .MuiTypography-root {
  font-weight: 700;
  text-transform: uppercase;
}

/*--- Desktop Only ---*/
@media (min-width: 992px) {
  .LoyaltyRewardsList {
    padding: 0 24px;
  }
}

/*--- Tablet and Mobile ---*/
@media (max-width: 991px) {
  .LoyaltyRewardsList .LoyaltyRewardWrapper {
    padding: 0 24px;
  }
}
