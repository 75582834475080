.SelectedRewardWrapper {
  display: flex;
  margin: 8px auto 0px;
  position: relative;
}

.SelectedReward {
  display: flex;
}

.SelectedReward .Title {
  flex: 1;
  margin-right: 8px;
}

.SelectedRewardWrapper .FeedbackSticker {
  flex: 1;
  margin-bottom: 0;
  transition: 0.5s ease-out;
}

.SelectedRewardWrapper .FeedbackSticker p:nth-child(n + 2) {
  margin-top: 0;
}

.SelectedRewardWrapper .SelectedRewardCancelButton {
  width: 64px;
  font-size: 1.2em;
  color: var(--color-text-primary);
  transition: 0.5s ease-out;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .SelectedRewardWrapper.fromCart:focus .FeedbackSticker {
    transform: translateX(-70px);
  }

  .SelectedRewardWrapper.fromCart:focus .SelectedRewardCancelButton {
    transform: translateX(0);
  }

  .SelectedRewardWrapper.fromCart .SelectedRewardCancelButton {
    width: 70px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transform: translateX(70px);
  }
}
