.IngredientChoiceWrapper.disabled {
  opacity: 1 !important;
}

.IngredientChoiceWrapper.IngredientBig {
  width: 150px;
}

.IngredientChoiceWrapper.IngredientMedium {
  width: 134px;
}

.IngredientChoiceWrapper.IngredientSmall {
  width: 160px;
}

.IngredientChoiceWrapper.IngredientExtraSmall {
  width: 110px;
}

.IngredientChoiceWrapper.IngredientMinimal {
  width: 90px;
}

.IngredientChoiceWrapper.IngredientMinimal .IconInfo {
  display: none;
}

.IngredientChoiceWrapper.IngredientMinimal .IngredientChoiceName {
  padding: 0 5px;
}

.IngredientChoiceWrapper.IngredientMinimal .IngredientChoiceName .Energy {
  display: none;
}

.IngredientChoiceWrapper.IngredientMinimal .IngredientChoiceName .Name {
  text-align: center;
  margin: 5px 0 0;
}

.IngredientChoiceWrapper.IngredientMinimal .IngredientChoice .IngredientPicture img {
  object-fit: initial;
  position: initial;
  top: initial;
  transform: initial;
}

.IngredientChoiceWrapper.IngredientMinimal .Status {
  top: 15%;
}

.IngredientChoiceWrapper.IngredientMinimal .IngredientChoice .Price {
  display: none;
}

.IngredientChoiceWrapper {
  padding-right: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
}

.IngredientChoiceWrapper.IngredientSmall .IngredientChoiceMultiple {
  min-height: 196px;
}

.IngredientChoice {
  height: 100%;
  border: 1px solid var(--color-secondary);
  background: white;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  padding: 0 0 10px 0;
  flex: 1;
}

.IngredientChoice .IngredientDetails {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.IngredientDetails > li {
  display: flex;
}

.IngredientMedium .IngredientDetails > li {
  display: flex;
  align-items: center;
}

.IngredientDetails > li:first-child,
.IngredientDetails > li:nth-child(2) {
  position: relative;
}

.IngredientDetails > li:nth-child(2) {
  flex: 1 1 auto;
}

.IngredientChoice:hover {
  cursor: pointer;
}

.IngredientChoice.selected::before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0px;
  background-color: var(--color-primary);
  opacity: 0.45;
  z-index: 1000;
  display: none;
}

.IngredientChoice.selected {
  border: 1px solid var(--color-primary) !important;
  background-color: var(--color-panel-primary);
}

.IngredientChoice.unavailable .IngredientPicture::before {
  content: 'Unavailable';
  font-family: 'Work Sans';
  font-size: 16px;
  font-weight: 600;
  color: var(--color-text-grey);
  vertical-align: middle;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 9888;
  background-color: rgba(255, 255, 255, 0.8);
}

.IngredientChoice.unavailable .IngredientChoiceName::before {
  content: '';
  font-size: 20px;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 9888;
  background-color: rgba(255, 255, 255, 0.35);
  pointer-events: none;
}

.IngredientChoice .Status {
  display: none;
}

.IngredientChoice.selected .Status {
  display: flex;
  color: var(--color-text-grey);
  position: absolute;
  z-index: 2321;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 1em;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
}

.IngredientChoice.selected .Status .Price {
  display: inline-flex;
  font-family: 'Work Sans';
  font-size: 20px;
  font-weight: 700;
}

.IngredientChoice.selected .Status .Price .MuiTypography-root {
  font-size: 20px;
  font-family: inherit;
  font-weight: 700;
}

.IngredientBig .IngredientChoice.selected .Status {
  left: 0;
  top: 0;
}

.IngredientMedium .IngredientChoice.selected .Status {
  left: 0;
  top: 0;
}

.IngredientSmall .IngredientChoice.IngredientChoiceMultiple.selected .Status {
  left: initial;
  top: initial;
  padding: 0px 0 0 0;
}

.IngredientSmall .IngredientChoice.selected .Status {
  left: 0;
  top: 0;
}

.IngredientSmall .IngredientChoice.selected .Status svg {
  padding-right: 4px;
  vertical-align: middle;
}

.IngredientExtraSmall .IngredientChoice.selected .Status {
  padding: 0 0 0 0;
}

.IngredientChoice .IngredientPicture {
  width: 100%;
  overflow: hidden;
  background-color: transparent;
}

.IngredientBig .IngredientPicture {
  height: 150px;
}

.IngredientMedium .IngredientPicture {
  height: 130px;
}

.IngredientSmall .IngredientChoice .IngredientPicture {
  max-height: 160px;
}

.IngredientSmall .IngredientChoice.IngredientChoiceMultiple.selected .IngredientPicture {
  /* height: 50px; */
}

.IngredientSmall .IngredientChoice.IngredientChoiceMultiple.selected .IngredientDetails {
  padding-bottom: 24px;
}

.IngredientChoiceWrapper.IngredientExtraSmall .IngredientPicture {
  width: 100%;
  height: 96px;
  overflow: hidden;
}

.CounterWrapper {
  display: none;
}

.CounterWrapper button .ButtonIcon.fa {
  color: var(--color-text-contrast);
}

.IngredientChoice.selected .CounterWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  background-color: var(--color-primary);
  border: none;
  z-index: 9889;
  overflow: hidden;
}

.IngredientChoiceWrapper .IngredientChoice.selected .IngredientPicture {
  position: relative;
}
.IngredientChoiceWrapper:not(.IngredientMinimal)
  .IngredientChoice.selected.addOn
  .IngredientDetails
  li:first-child::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  background-color: var(--color-primary);
  opacity: 0.4;
  display: none;
}

.IngredientChoice .IngredientPicture img {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: relative;
}

.IngredientChoiceName {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 8px 8px 0 8px;
  flex: 1 1;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.IngredientChoiceName .Name {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.1em;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  margin-bottom: 8px;
}

.IngredientChoiceName .Energy {
  color: #7a7a7a;
  font-size: 12px;
  width: auto;
  margin-bottom: 0;
}

.IngredientChoiceName .IngredientChoiceTitleRow,
.IngredientChoiceName .IngredientChoiceEnergyRow {
  display: flex;
}

.IconInfo {
  color: #2f2f2f;
  font-size: 14px;
  z-index: 9988;
  margin-left: 10px;
}

.IconInfo > span {
  padding-top: 0px;
  justify-content: flex-start;
}

.IngredientChoiceTitleRow .IconInfo {
  display: none;
  align-items: flex-start;
  justify-content: flex-start;
}

.IngredientSmall .IngredientChoice.selected .Status .line-break {
  display: block;
  height: 8px;
}

.IngredientChoiceWrapper.disabled .IngredientChoice {
  border: none;
}
.IngredientChoiceWrapper.disabled .IngredientChoice::after {
  content: '';
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #7a7a7a;
  opacity: 0.4;
}
.IngredientChoiceWrapper.disabled .IngredientChoice.unavailable::after {
  background-color: #aaaaaa;
  opacity: 0.2;
}
.IngredientChoiceWrapper.disabled .IngredientChoice.unavailable .IngredientPicture::before {
  background: none;
}
.IngredientChoiceWrapper.disabled .IngredientChoice.unavailable .IngredientChoiceName::before {
  background: none;
}

.IngredientChoice.unavailable {
  border: none;
}

.IngredientChoice.unavailable::before {
  content: '';
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
  z-index: 1;
}

.IngredientMinimal .IngredientChoice.selected .Status {
  display: none;
}

/*--- Large Desktop Only---*/
@media (min-width: 1800px) {
  .IngredientSmall .IngredientChoice.IngredientChoiceMultiple.selected .Status {
    padding-top: 0;
  }
}

/*--- Tablet and Mobile ---*/
@media (min-width: 993px) {
  .IngredientSmall .IngredientChoice.IngredientChoiceMultiple.selected .IngredientPicture {
    /* height: 160px; */
    margin-bottom: -40px;
  }

  .IngredientSmall .IngredientChoice.IngredientChoiceMultiple.selected .IngredientPicture::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 253, 248, 0.2) 50%,
      #fffdf8 100%
    );
  }

  .IngredientSmall .IngredientChoice.IngredientChoiceSingle.selected .IngredientPicture::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 253, 248, 0.2) 70%,
      #fffdf8 100%
    );
  }

  .IngredientSmall .IngredientChoice .IngredientChoiceName .Name {
    margin-top: auto;
    margin-bottom: 4px;
    display: flex;
    flex: 0 1 auto;
  }

  .IngredientSmall .IngredientChoice .IngredientIncludeLabel {
    padding-top: 0;
    margin-top: 0;
  }

  .IngredientChoiceTitleRow .IconInfo {
    display: flex;
  }

  .IngredientChoiceEnergyRow .IconInfo {
    display: none;
  }
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .IngredientSmall .IngredientChoice.unavailable::before {
    justify-content: flex-start;
    padding-bottom: 0px;
  }

  .IngredientChoice.unavailable .IngredientPicture::before {
    font-size: 14px;
  }

  .IngredientChoiceWrapper.IngredientSmall {
    width: 100%;
    max-width: none;
    padding-right: 0;
    padding-bottom: 8px;
  }

  .IngredientChoiceWrapper.IngredientMedium {
    width: 164px;
  }

  .IngredientMedium .IngredientChoice .IngredientPicture {
    height: 150px;
  }

  .IngredientMedium .IngredientDetails > li {
    display: flex;
    align-items: center;
  }

  .IngredientMedium .IngredientDetails > li:nth-child(2) {
    padding-right: 8px;
  }

  .IngredientChoiceWrapper.IngredientSmall .IngredientChoice {
    padding-bottom: 0;
  }

  .IngredientSmall .IngredientChoiceMultiple.IngredientChoice.selected .Status {
    width: 98px;
  }

  .IngredientSmall .IngredientChoice .IngredientDetails {
    flex-direction: row;
    padding-right: 10px;
  }

  .IngredientSmall .IngredientDetails > li {
    display: flex;
    align-items: center;
  }

  /* Element that contains the picture */
  .IngredientSmall .IngredientDetails > li:nth-child(1) {
    width: 98px;
  }

  /* Element that contains details*/
  .IngredientSmall .IngredientDetails > li:nth-child(2) {
    flex: 1 1 auto;
  }

  .IngredientSmall .IngredientPicture {
    width: 98px;
    background-color: #ffffff;
  }

  .IngredientSmall .IngredientChoice.selected .Status {
    width: 98px;
  }

  .IngredientSmall .IngredientChoice.selected .Status svg {
    width: 32px;
    height: 32px;
    padding-right: 0;
  }

  .IngredientSmall .IngredientChoice.IngredientChoiceMultiple.selected .IngredientDetails {
    min-height: 130px;
    padding-bottom: 42px;
  }

  .IngredientSmall .IngredientChoice.IngredientChoiceMultiple.selected .IngredientPicture {
    height: 86px;
  }

  .IngredientSmall .IngredientChoiceName {
    padding: 10px 0 8px 16px;
  }

  .IngredientChoiceWrapper.IngredientMinimal {
    width: 30%;
    max-width: 115px;
  }

  .IngredientChoice.selected .CounterWrapper {
    padding: 7px 0;
  }

  .IngredientChoiceMultiple .IconInfo,
  .IngredientSmall .IconInfo {
    position: unset;
  }

  .IngredientSmall .IngredientChoiceName {
    justify-content: center;
  }

  .IngredientChoiceWrapper.IngredientSmall .IngredientChoiceMultiple {
    min-height: 108px;
  }

  .IngredientChoice.selected {
    background-color: var(--color-modal-primary);
  }
}

/* Tablet Only */
@media (min-width: 768px) and (max-width: 992px) {
  .IngredientChoiceWrapper.IngredientExtraSmall {
    width: 130px;
  }

  .IngredientChoiceWrapper.IngredientExtraSmall:nth-child(4n) {
    padding-right: 0;
  }

  .IngredientExtraSmall .IngredientPicture img {
    height: 130px;
  }

  .IngredientExtraSmall .IngredientChoiceName h5 {
    -bottom: 6px;
  }

  .IngredientExtraSmall.s .Status span {
    font-size: 3.8em;
    padding: 0 !important;
  }
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px) {
  .IngredientChoiceWrapper.IngredientBig,
  .IngredientChoiceWrapper.IngredientMedium {
    width: calc((100% / 3) - (14px * 2 / 3));
    position: relative;
    padding-bottom: 10px;
    padding-right: 14px;
  }

  .IngredientChoiceWrapper.IngredientBig:nth-child(3n),
  .IngredientChoiceWrapper.IngredientMedium:nth-child(3n) {
    padding-right: 0;
  }

  .IngredientChoiceWrapper.IngredientExtraSmall {
    width: calc((100% / 4) - (14px * 3 / 4));
    position: relative;
    padding-bottom: 10px;
    padding-right: 14px;
  }

  .IngredientChoiceWrapper.IngredientExtraSmall:nth-child(4n) {
    padding-right: 0;
  }

  .IngredientExtraSmall .IngredientChoice.selected .Status {
    padding: 0 0 0 0;
  }

  .IngredientBig .IngredientPicture {
    width: 100%;
    height: 150px;
    overflow: hidden;
  }

  .IngredientMedium .IngredientPicture {
    width: 100%;
    height: 150px;
    overflow: hidden;
  }

  .IngredientExtraSmall .IngredientPicture img {
    width: 100%;
    height: calc((100vw - 42px) / 4);
    overflow: hidden;
    background-color: #ffffff;
  }

  .IngredientExtraSmall .IngredientChoiceName h5 {
    margin-bottom: 0;
  }

  .IngredientChoice {
    padding-bottom: 10px;
  }
}

/* Mobile Portrait Only */
@media (max-width: 479px) {
  .IngredientChoiceWrapper.IngredientBig,
  .IngredientChoiceWrapper.IngredientMedium {
    width: calc(100% / 2 - 7px);
    position: relative;
    padding-right: 0;
  }

  .IngredientChoiceWrapper.IngredientBig:nth-child(odd),
  .IngredientChoiceWrapper.IngredientMedium:nth-child(odd) {
    width: calc(100% / 2 - 7px);
    padding-right: 14px;
  }

  .IngredientBig .IngredientChoice .IngredientPicture,
  .IngredientMedium .IngredientChoice .IngredientPicture {
    width: 100%;
    height: calc((100vw - 54px) / 2);
    overflow: hidden;
  }
  .IngredientExtraSmall .IngredientChoice.selected .Status {
    padding: 0 0 0 0;
  }

  .IngredientChoiceWrapper.IngredientExtraSmall {
    width: calc(100% / 2 - 7px);
    position: relative;
    padding-right: 0;
  }

  .IngredientChoiceWrapper.IngredientExtraSmall:nth-child(odd) {
    width: calc(100% / 2 - 7px);
    padding-right: 14px;
  }

  .IngredientChoiceWrapper.IngredientExtraSmall .IngredientPicture {
    width: 100%;
    height: calc((100vw - 54px) / 2);
    overflow: hidden;
  }

  .IngredientChoiceWrapper.IngredientMinimal {
    width: calc(100% / 4 - 10px);
    padding-right: 10px;
    padding-bottom: 10px;
  }
}
