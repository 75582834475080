/* .item */
.MenuItem {
  width: 18vw;
  max-width: 192px;
  margin: 5px;
  position: relative;
  display: flex;
  box-shadow: 0 0 0 1px #dcb59d;
  border-radius: 16px;
  background: var(--color-background);
  overflow: hidden;
  z-index: 1;
  padding-bottom: 8px;
}

.MenuItem:hover {
  cursor: pointer;
  box-shadow: 0 0 0 1px #dcb59d;
  background-color: var(--color-panel-primary);
}

.MenuItem a {
  width: 100%;
}

.MenuItem div {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.MenuItem .menu-image {
  position: relative;
}

.MenuItem .menu-image img {
  width: 100%;
  height: 18vw;
  max-height: 192px;
  z-index: 1;
}

.MenuItem.unavailable {
  box-shadow: none;
  opacity: 0.8;
}

.menu-desc.unavailable::before {
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 9888;
  background-color: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(1px);
}

.menu-image.unavailable::before {
  content: 'Unavailable';
  font-size: 16px;
  font-family: 'Work Sans';
  font-weight: 600;
  color: var(--color-text-grey);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 9888;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(1px);
}

.menu-desc {
  background: transparent;
  position: relative;
  z-index: 1000;
  color: var(--color-text-primary);
  text-align: left;
  padding: 10px 12px 36px 12px;
  width: 100%;
  flex-grow: 1;
}

.menu-desc h5 {
  font-size: 14px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  max-width: 98%;
  overflow: hidden;
  line-height: 1.2em;
}

.menu-desc .PriceWrapper {
  position: absolute;
  bottom: 0px;
  left: 12px;
}

.menu-desc .Price {
  font-size: 14px;
  color: var(--color-text-grey);
}

.menu-desc .From {
  font-size: 0.8em;
  font-style: italic;
  color: #95989a;
  padding-right: 5px;
}

.menu-desc .EnergyWrapper {
  position: absolute;
  bottom: 0px;
  right: 12px;
}

.menu-desc .Energy {
  color: #7a7a7a;
  font-size: 14px;
}

.menu-desc ul li {
  display: inline-flex;
}

.MenuItem .PromoFlag {
  font-size: 0.75em;
  color: var(--color-text-contrast);
  padding: 3px 7px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.MenuItem .UpsellStatus {
  display: none;
}

.MenuItem.isUpselling {
  border: 1px solid var(--color-primary);
  box-shadow: none;
}

.MenuItem.isUpselling.isUpsellSelected {
  border: 1px solid var(--color-beige);
  box-shadow: none;
}

/* Mobile Landscape */
@media (max-width: 767px) and (min-width: 480px) {
  /* .item */
  .MenuItem {
    width: 27vw;
    max-width: none;
    margin: 8px;
  }

  .MenuItem .menu-image > img {
    height: 29vw;
    max-height: none;
  }
}

/* Mobile Portrait */
@media (max-width: 479px) {
  .MenuItem {
    width: 40vw;
    max-width: none;
    margin: 8px;
  }

  .MenuItem .menu-image > img {
    height: 44vw;
    max-height: none;
  }
}
