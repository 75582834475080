.IngredientsSectionHeader {
  text-align: left;
  padding: 2px 20px;
  display: flex;
}

.IngredientsSectionHeader .IngredientsSectionName {
  display: flex;
  align-items: center;
}
