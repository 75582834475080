.BonusSticker {
  font-family: inherit;
  font-size: inherit;
}

.BonusSticker .BonusMultiplierLabel {
  background-color: var(--color-primary);
  color: var(--color-text-contrast);
  padding: 4px 10px;
  margin-right: 8px;
  border-radius: 50px;
  font-weight: 700;
}

.BonusSticker .BonusTitle {
  display: inline-flex;
}
