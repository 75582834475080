.wrapper {
  position: relative;
  width: 36px;
  height: 36px;
  overflow: hidden;
  margin: 50px auto;
}

.leftHalf,
.rightHalf,
.spinner {
  top: 0;
  position: absolute;
  width: 50%;
  height: 100%;
}

.leftHalf {
  left: 0;
  background: #ffffff;
  z-index: 3;
  opacity: 0;
  animation: showHide 4s infinite steps(1, end) reverse;
}

.rightHalf {
  right: 0;
  background: var(--color-primary);
  z-index: 1;
  opacity: 1;
  animation: showHide 4s infinite steps(1, end);
  border-radius: 0 100% 100% 0/ 0 50% 50% 0;
}

.spinner {
  left: 0;
  background: var(--color-primary);
  animation: spin 4s linear infinite;
  transform-origin: center right;
  z-index: 2;
  border-radius: 100% 0 0 100%/ 50% 0 0 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes showHide {
  0% {
    opacity: 1;
  }

  50%,
  100% {
    opacity: 0;
  }
}
