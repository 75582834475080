.OrderItemPrice {
  display: flex;
  padding-right: 2px;
}

.OrderItemPrice span {
  margin: auto;
}

.OrderItemPrice .OriginalPrice {
  padding-top: 0px;
}

.OrderItemPrice.isDiscounted .OriginalPrice {
  text-decoration: line-through;
  margin-right: 10px;
  font-size: 0.9em;
  opacity: 0.7;
}

.OrderItemPrice .DiscountedPrice {
  padding-top: 0px;
}
