.KioskPairingModal .Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.KioskPairingModal .Title,
.KioskPairingModal .Text {
  padding-bottom: 16px;
}

.KioskPairingModal .Button {
  border-width: 2px;
  border-radius: 60px;
}
