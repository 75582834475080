.ScannerModal .modal-dialog {
  display: flex;
  flex-direction: column;
}

.ScannerModal .modal-content {
  height: 100%;
}

.ScannerModal .BarcodeRetryButton {
  margin-top: 16px;
  padding: 8px 48px;
}

@media (max-width: 767px) {
  .ScannerModal {
    flex-direction: column;
  }
  .ScannerModal .modal-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0;
    overflow: auto;
    border-radius: 0;
  }

  .ScannerModal .modal-body {
    overflow: auto;
    border-radius: 0;
  }

  .ScannerModal .modal-dialog {
    width: 100%;
    max-width: none;
    display: flex;
    flex: 1;
    overflow: auto;
  }

  .ScannerModal .Topbar {
    display: flex;
    align-items: center;
    height: 56px;
    width: 100%;
    background-color: var(--color-primary);
    flex: 0 0 auto;
    color: var(--color-text-secondary);
    padding: 0 16px;
  }

  .ScannerModal .Topbar .fa {
    font-size: 1.2em;
  }

  .ScannerModal .Topbar .Title {
    flex: 1;
  }

  .ScannerModal .Topbar .TopbarButton {
    font-size: 1.4em;
    color: var(--color-text-secondary);
  }

  .ScannerModal .Topbar .ScanHistoryButton svg {
    width: 30px;
  }

  .ScannerModal .BarcodeScannerContainer {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ScannerModal .fa {
    font-size: 6em;
    height: fit-content;
  }

  .ScannerModal .ScanInstructions {
    color: var(--color-text-contrast);
    background-color: black;
    padding: 25px;
    height: fit-content;
  }

  .ScannerModal .modal-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0;
    padding: 0;
    background-color: #434343;
  }

  .ScannerModal .BarcodeScannerComponent {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .ScannerModal .ErrorSticker {
    margin: auto 20%;
    padding: 16px;
    border-radius: 15px;
    border: none;
    font-size: 12px;
    background-color: var(--color-background);
    color: var(--color-text-grey);
  }

  .ScannerModal p {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ScannerModal .ErrorSticker img {
    width: fit-content;
    margin-bottom: 8px;
  }

  .ScannerModal .ErrorSticker .fa {
    font-size: 2em;
  }

  .Rectangle {
    position: absolute;
    width: 60vw;
    height: 60vw;
    z-index: 999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 3px solid #00ff3b;
    background-color: 'transparent';
  }
}

.ScannerModal .DesktopNote {
  margin: 16px;
}
