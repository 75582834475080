.PasswordInput {
  position: relative;
}

.PasswordInput svg {
  position: absolute;
  right: 16px;
  top: calc(50% - 9px);
}

.PasswordInput input[type='password'] ~ svg {
  top: calc(50% - 8px);
}

.PasswordInput input {
  margin-bottom: 0;
}
