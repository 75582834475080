.MenuSidebarWrapper {
  display: flex;
  flex: 1;
  padding-top: 16px;
  padding-left: 48px;
  max-width: 600px;
}

.MenuSidebar .MenuSidebarItem a.active .MuiTypography-root {
  color: var(--color-light);
}

.MenuSidebarItem {
  margin-bottom: 24px;
  text-transform: uppercase;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .MenuSidebarWrapper {
    flex: 0;
    padding: 16px 16px 8px;
    white-space: nowrap;
  }

  .MenuSidebar {
    overflow: auto;
  }

  .MenuSidebar ul {
    display: flex;
    overflow: auto;
  }

  .MenuSidebarItem {
    margin-bottom: 0;
    margin-right: 24px;
  }
}
