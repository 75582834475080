.GuestPaymentUnavailableModal .Button {
  margin-top: 30px;
  justify-content: center;
}

.GuestPaymentUnavailableModal .modal-body {
  padding: 0 35px 40px 35px;
}

.GuestPaymentUnavailableModal .title {
  padding-bottom: 50px;
}
