.MenuDetailsAddHeader {
  padding: 0px 24px 8px 24px;
}

.MenuDetailsAddHeader .MenuDetailsAddTitle {
  display: flex;
}

.MenuDetailsAddHeader h4 {
  display: inline-block;
}

.MenuDetailsAddHeader .CategoryChoice {
  flex: 1;
}

.MenuDetailsAddHeader .CategoryChoice .CategoryPaginationBar {
  margin: 6px 0;
  text-align: right;
}

.MenuDetailsAddHeader .MenuDetailsAddDietTagsRow {
  margin: 8px 0 4px;
}

/* Mobile Portrait and Landscape */
@media (max-width: 767px) {
  .MenuDetailsAddHeader {
    padding: 24px 16px 0 24px;
    border-bottom: none;
  }

  .MenuDetailsAddHeader h4 {
    display: none;
  }

  .MenuDetailsAddHeader .CategoryChoice .CategoryPaginationBar {
    margin: 6px 0;
    text-align: right;
  }

  .MenuDetailsAddHeader .MenuDetailsAddDietTagsRow {
    display: none;
  }
}
