.PaymentPopupContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.PaymentPopupContainer .PaymentPopupText {
  max-width: 80%;
  text-align: center;
}

.PaymentPopupContainer .LoadingScreen {
  height: auto;
}
