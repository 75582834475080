.DiningChoiceContainer {
  width: 100%;
  margin-top: 20px;
  background-color: var(--color-content);
  border-radius: 16px;
}

.DiningChoiceContainer .tabsRow {
  display: flex;
  width: 100%;
  padding: 0 24px;
  justify-content: space-around;
}

.DiningChoiceContainer .Pickup,
.DiningChoiceContainer .Delivery {
  background-color: var(--color-content);
  border-radius: 0 0 16px 16px;
}

.DiningChoiceContainer .tabsRow .Button {
  font-size: 20px;
  font-weight: bold;
  padding: 4px 18px;
  margin: 16px auto 0px;
  border-radius: 60px;
  clip-path: none;
  box-shadow: none;
}

.DiningChoiceContainer .tabsRow .Button.selected,
.DiningChoiceContainer .tabsRow .Button.selected:focus {
  background-color: var(--color-text-hyperlink);
  border: none !important;
  color: var(--color-text-contrast);
}

.DiningChoiceContainer .tabsRow .Button > span {
  font-weight: 900;
}

/* Small Mobile Only*/
@media (max-width: 400px) {
  .DiningChoiceContainer .tabsRow .Button {
    padding: 4px 14px;
  }
}
