.Button.btn-facebook {
  background-color: #4267b2 !important;
  border: 1px solid #4267b2 !important;
  position: relative;
  margin-bottom: 16px;
  height: 52px;
}
.Button.btn-facebook:hover {
  background-color: #4267b2 !important;
  border: 1px solid #4267b2 !important;
}
.Button.btn-facebook:focus {
  background-color: #4267b2 !important;
  border: 1px solid #4267b2 !important;
}
.Button.btn-facebook .fa-facebook {
  font-size: 1.2em;
  width: 20px;
  height: 20px;
  color: #4267b2;
  background-color: white;
  border-radius: 100%;
  padding-top: 4px;
  margin-right: 8px;
  margin-bottom: 1px;
}

.SignInLogos {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.SignInLogos svg {
  height: auto;
  width: auto;
}

/* Desktop & Mobile Landscape Only */
@media (min-width: 480px) {
  .SignInLogos svg {
    transform: scale(1.1);
  }
}

/*--- Desktop Only ---*/
@media (min-width: 768px) {
  .SignInLogos svg {
    transform: scale(1.2);
  }
}
