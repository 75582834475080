.Checkbox label {
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: center;
  flex: 1;
  cursor: pointer;
}

.Checkbox label::before {
  content: '';
  min-width: 15px;
  min-height: 15px;
  border-radius: 3px;
  border: 2px solid var(--color-primary);
  fill: transparent;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.1s ease-in;
}

.Checkbox label:hover::before {
  background-color: rgb(244, 240, 240);
}

/* Checkmark */
.Checkbox label::after {
  position: absolute;
  content: '';
  height: 5px;
  width: 8px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
  margin-left: 4px;
  margin-bottom: 2px;
  color: var(--color-primary);
  opacity: 0;
  cursor: pointer;
}

/* Hide the checkmark by default*/
.Checkbox input[type='checkbox'] + label::after {
  content: '';
}

/*Unhide the checkmark on the checked state*/
.Checkbox input[type='checkbox']:checked + label::before {
  background-color: var(--color-primary);
}

.Checkbox input[type='checkbox']:checked + label::after {
  content: '';
  opacity: 1;
  color: var(--color-text-contrast);
}

.Checkbox input[type='checkbox']:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}
