.GglocationMapMarkerWrapper {
  position: relative;
}

.GglocationMapMarker {
  display: flex;
  max-width: 360px;
  height: 120px;
  border-radius: 16px;
  background-color: var(--color-modal-primary);
  overflow: hidden;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
}

.GglocationMapMarker .GglocationMapMarkerImage {
  flex: 2;
}

.GglocationMapMarker .GglocationMapMarkerImage img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.GglocationMapMarker .GglocationMapMarkerDetails {
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  font-size: 1.1em;
}

.GglocationMapMarker .GglocationMapMarkerDetails > div {
  margin: 4px 0;
  line-height: 20px;
}

.GglocationMapMarker .GglocationMapMarkerDetails > div:first-of-type {
  font-size: 1.2em;
  font-weight: bold;
}

.Map .infoBox {
  transform: translate(32px, -72px) !important;
  overflow: visible !important;
}
