/* .item */
.Gglocation {
  border-radius: 16px;
  overflow: hidden;
  display: block;
  margin-bottom: 8px;
  box-shadow: none;
  background-color: var(--color-content);
  border: 1px solid #dcb59d;
}
.Gglocation .GglocationName {
  /* Force redraw */
  position: relative;
  transform: translate(0, 0);
  z-index: 10;
}
.Gglocation:not(.disabled):hover {
  box-shadow: none;
}
.Gglocation.disabled {
  opacity: 0.5;
}

.Gglocation .store-img {
  position: relative;
  top: 0;
  width: 100%;
  height: 96px;
  display: none;
}
.Gglocation .store-img img {
  width: 100%;
  height: 96px;
  object-fit: cover;
}
.Gglocation ul.store-info {
  background-color: var(--color-content);
  color: var(--color-text-grey);
  text-align: left;
  padding: 16px;
  position: relative;
  width: 100%;
  border-radius: 16px;
}
.Gglocation ul.store-info .StoreInfoAddress {
  margin: 4px 0;
}
.Gglocation ul.store-info li {
  display: inline-block;
  font-size: 0.9em;
}

.Gglocation ul.store-info li:first-child img {
  width: 17px;
  height: auto;
  margin-right: 3px;
}
.Gglocation ul.store-info li:nth-child(2) img {
  width: 14px;
  height: auto;
  margin-right: 3px;
}
.Gglocation ul.store-info li:nth-child(2) {
  position: absolute;
  right: 12px;
}

.Gglocation ul.store-info strong,
.Gglocation ul.store-info .optionalBold {
  font-weight: 600;
}

.Gglocation ul.store-info .Distance {
  color: #7a7a7a;
}

.Gglocation.selected {
  border: 1px solid var(--color-dark) !important;
  background-color: var(--color-modal-primary);
}

.Gglocation.selected ul.store-info {
  background-color: var(--color-modal-primary);
}

.Gglocation.selected .store-img::before {
  content: '';
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  /* .item */
  .Gglocation {
    margin-bottom: 8px;
  }
}
