.TwoColumns {
  display: flex;
  flex-direction: row-reverse;
  overflow: auto;
  flex: 1;
  width: 100%;
  margin: 0 auto;
}

.TwoColumns li.column {
  width: 50%;
  text-align: center;
  padding-bottom: 60px;
}

.TwoColumns li.column:first-child {
  padding-top: 24px;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .TwoColumns {
    flex-direction: column;
  }

  .TwoColumns li.column {
    padding-bottom: 24px;
    width: 100%;
  }

  .TwoColumns li.column:first-child {
    border-left: none;
    padding-bottom: 0;
  }
}
