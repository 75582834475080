.PersonalizePreferenceGroups {
  margin: 0px 24px;
}

/* Mobile Portrait and Landscape */
@media (max-width: 767px) {
  .PersonalizePreferenceGroups {
    margin: 0px 16px;
  }
}
