.ProgressBar {
  height: 5px;
  background-color: var(--color-beige);
}
.ProgressBarBackground {
  height: 5px;
  width: 100%;
  background-color: #e0e0e0;
  overflow: visible;
  position: relative;
}

.ProgressBarBackground .ProgressBarStep {
  position: absolute;
  top: -24px;
}
