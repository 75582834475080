.MenuDetailsView .PricingVariationsNotifications {
  padding-left: 16px;
}

.MenuDetailsView .PricingVariationsNotification {
  margin-left: 16px;
  flex: 1;
  max-width: unset;
}

.MenuDetailsAdd .PricingVariationsNotifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 24px;
}

.MenuDetailsAdd .PricingVariationsNotification {
  width: 100%;
}

.PricingVariationsNotifications:empty {
  display: none;
}

.PricingVariationsNotification {
  font-size: 0.85em;
  display: flex;
  margin: 0px 0 0;
  padding: 12px 16px;
  flex: 1;
}

.PricingVariationsNotification .SaladIcon {
  flex: 0;
  height: 20px;
}

.PricingVariationsNotification .Text {
  display: flex;
  flex: 1;
  align-items: center;
}

.PricingVariationsNotification .Text .Content {
  display: inline;
  justify-content: center;
  width: 100%;
  text-align: left;
}

.PricingVariationsNotification .Text .Content .MuiTypography-root {
  display: inline;
}

.PricingVariationsNotification .Text .PreviousPrice {
  text-decoration: line-through;
  margin-left: 10px;
  margin-right: 5px;
  color: #c2bfbb;
}

.PricingVariationsNotification .Add {
  flex: 0;
  margin-left: 16px;
}

@media (max-width: 992px) {
  .PricingVariationsNotification {
    width: 100%;
  }
}
