.IngredientModal .modal-dialog {
  width: 70% !important;
  max-width: 780px !important;
}

.IngredientModal .modal-body {
  display: flex;
  text-align: left;
  padding: 0;
  font-size: 0.95em;
  overflow: hidden;
}

.IngredientModal .modal-control {
  position: absolute;
}

.IngredientModal .modal-body p {
  padding-left: 15px;
  margin-bottom: 5px;
}

.IngredientModal .modal-body ul {
  padding-left: 15px;
  display: flex;
}

.IngredientModal .modal-body ul li {
  display: inline-flex;
  flex: 1;
  padding: 3px 0;
}

.IngredientModal .modal-body ul li.NutrientValue {
  justify-content: flex-end;
  padding-right: 16px;
}

.IngredientModal .ServingWeight {
  margin-bottom: 16px;
}

.IngredientModal .ServingWeight li:first-child {
  flex: 2;
}

.IngredientModal .ServingWeight li:last-child {
  flex: 1;
}

.IngredientModal .IngredientInfo {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 16px 24px 8px;
}

.IngredientModal .IngredientInfo .IngredientName {
  display: none;
}

.IngredientModal .IngredientDescription {
  width: 80%;
  color: #2f2f2f !important;
  padding-bottom: 11px;
  text-align: left;
}

.IngredientModal .NutrientsList {
  column-count: 2;
}

.IngredientModal h5 {
  padding-left: 15px;
  padding-top: 16px;
  padding-bottom: 22px;
}

.IngredientModalImage {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.IngredientModalImage img {
  width: 100%;
  object-fit: cover;
  height: auto;
}

.IngredientModal .modal-body .NutritionalDisclaimer {
  margin-top: 16px;
}

.IngredientModal .IngredientModalImage .IngredientName {
  font-weight: 700;
  margin: 8px 24px;
}

@media (min-width: 992px) {
  .IngredientModal .NutrientsList ul li:first-child {
    flex: 2;
  }

  .IngredientModal .ServingWeight {
    width: 50%;
  }

  .IngredientModal .ServingWeight li:last-child {
    padding-right: 24px;
    justify-content: flex-end;
  }
}

/*--- M1: tablet and mobile ---*/
@media (max-width: 992px) {
  .IngredientModal .modal-dialog {
    width: 100% !important;
    max-width: none !important;
    padding: 0 27.5px;
  }

  .IngredientModal .IngredientInfo {
    padding: 32px 32px 24px 24px;
  }

  .IngredientModal .modal-content {
    top: 0vh !important;
  }

  .IngredientModal .modal-body {
    flex-direction: column;
  }

  .IngredientModal .modal-body .IngredientModalImage {
    width: 100%;
    height: 20vh;
    background-color: #ffffff;
    overflow: hidden;
    text-align: center;
    display: none;
  }

  .IngredientModal .modal-body .IngredientModalImage img {
    height: 100%;
    object-fit: cover;
  }

  .IngredientModal .modal-body .IngredientInfo {
    position: relative;
    top: 0;
    left: 0;
    text-align: left;
  }

  .IngredientModal .modal-body .IngredientInfo ul {
    padding-left: 15px;
  }

  .IngredientModal .modal-body .IngredientInfo ul li {
    padding: 0;
  }

  .IngredientModal .modal-body .IngredientInfo ul li:nth-child(2) {
    font-weight: 400;
  }

  .IngredientModal .modal-body .NutritionalDisclaimer {
    width: 100%;
    padding-bottom: 15px;
    line-height: 1.2em;
  }

  .IngredientModal .IngredientDescription {
    width: 100%;
    text-align: center;
  }

  .IngredientModal .IngredientInfo .IngredientName {
    display: block;
    text-align: center;
    margin-bottom: 16px;
  }
  .IngredientModal .IngredientInfo .NutritionalInformation {
    display: none;
  }

  .IngredientModal .ServingWeight li:nth-child(2) {
    justify-content: flex-end;
  }
}

@media (max-width: 479px) {
  .IngredientModal .modal-body .IngredientModalImage {
    display: none;
  }

  .IngredientModal .NutrientsList {
    column-count: 1;
  }
}
