.Badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  margin: 0 8px;
  border-radius: 60px;
}

.Badge.Primary {
  background-color: var(--color-primary);
  color: var(--color-text-contrast);
}

.Badge.outline.Primary {
  background-color: transparent;
  color: var(--color-text-primary);
}

.Badge.Secondary {
  background-color: var(--color-secondary);
  color: var(--color-text-contrast);
}

.Badge.outline.Secondary {
  background-color: transparent;
  color: var(--color-text-secondary);
}

.Badge.Success {
  background-color: var(--color-beige);
  color: var(--color-text-contrast);
}

.Badge.outline.Success {
  background-color: transparent;
  color: var(--color-beige);
}

.Badge.Error {
  background-color: var(--color-text-error);
  color: var(--color-text-contrast);
}

.Badge.outline.Error {
  background-color: transparent;
  color: var(--color-text-error);
}

.Badge.Disabled {
  background-color: #8ca1a5;
  color: var(--color-text-contrast);
}

.Badge.outline.Disabled {
  background-color: transparent;
  color: #8ca1a5;
}
