.TopBarCartButton {
  display: flex;
  position: relative;
  margin-left: 16px;
}

.TopBarCartButton .Quantity {
  background-color: #f4d54c;
  display: block;
  height: 16px;
  padding: 2px 6px;
  border-radius: 8px;
  color: var(--color-text-primary);
  position: absolute;
  top: 0px;
  left: 22px;
  font-size: 0.65em;
  line-height: 12px;
  font-weight: 600;
}

.TopBarCartButton img {
  height: 40px;
}
