.OrderPaymentLine {
  display: flex;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 8px;
}
.OrderPaymentLine li {
  font-size: 1.1em;
  vertical-align: text-top;
}
.OrderPaymentLine li:first-child {
  text-align: left;
}
.OrderPaymentLine li:first-child p {
  margin-bottom: 0;
}
.OrderPaymentLine li:last-child {
  text-align: right;
  width: 24%;
}
.OrderPaymentLine li:last-child p {
  display: inline;
}
.OrderPaymentLine li.total {
  color: var(--color-text-grey);
  font-weight: 600;
}
.OrderPaymentLine.discount li:last-child {
  font-weight: normal;
}
.OrderPaymentLine .Tax {
  color: var(--color-text-grey);
  font-size: 0.7em;
  text-align: left;
}
.OrderPaymentLine .Disclaimer {
  font-size: 0.9em;
  text-align: left;
  width: 60%;
  margin: 15px auto 5px auto;
}

.OrderPaymentLine li > span {
  line-height: 1.5em;
}

/*--- Desktop Only ---*/
@media (min-width: 768px) {
  .OrderPaymentLine li .total {
    font-size: 2em;
  }
}

/*--- Mobile and Tablet ---*/
@media (max-width: 992px) {
  .OrderPaymentLine {
    padding: 0 24px;
    width: 100%;
  }
  .OrderPaymentLine .Disclaimer {
    width: 80%;
  }
}
