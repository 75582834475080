.CyoDetails {
  background-color: var(--color-cyo-content);
  width: 100%;
  flex: 1;
  border-bottom: 1px solid #ffffff;
  padding-top: 14px;
  overflow: auto;
}

.CyoDetails .header {
  padding: 0 30px;
  color: var(--color-text-contrast);
}

.CyoDetails .header > h5 {
  font-size: 1.2em !important;
}

.CyoDetails .header > p {
  color: #454545 !important;
  margin-top: 0 !important;
  font-size: 1em !important;
}

.CyoDetails .header > p span {
  padding: 0 2px;
}

.CyoDetails > .content {
  flex: 1 1 auto;
  margin-left: 23px;
  padding-bottom: 5px;
  overflow: auto;
  display: flex;
}

.CyoDetails .IngredientsSectionHeader {
  padding: 0;
  display: inline-block;
}

.CyoDetails .nav-tabs > li {
  flex: initial;
  padding: 0 10px;
}

.CyoDetails .nav-tabs > li:first-child {
  padding-left: 0;
}

.CyoDetails .nav-tabs > li > a .IngredientsSectionName .Name {
  font-family: 'Sharp Grotesk Medium15';
  letter-spacing: 0.05em;
  white-space: nowrap;
}

.CyoDetails .nav-tabs > li.active > a {
  border-bottom: none !important;
}

.CyoDetails .nav-tabs > li.active > a .IngredientsSectionName .Name {
  font-family: 'Sharp Grotesk Bold15';
  color: var(--color-light);
  font-size: 1.06em;
  line-height: 1.48;
}

.CyoDetails li.item-added {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  width: 96px;
  height: auto;
  margin: 0 5px 10px 5px;
  position: relative;
}

.CyoDetails li.item-added .item-img {
  width: 100%;
  height: 80px;
  overflow: hidden;
}

.CyoDetails li.item-added .item-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.CyoDetails li.item-added .item-name {
  padding: 6px 8px;
  width: 96px;
  position: relative;
  text-align: center;
  margin: auto;
  line-height: 1.2em;
  font-size: 0.9em;
}

.CyoDetails li.item-added .item-name p {
  margin-bottom: 0;
}

.CyoDetails .img-remove::after {
  padding-top: 80px;
}

.CyoDetails .item-remove::after {
  top: 10%;
  transform: translateY(0%);
  left: 30%;
  width: 49%;
}

.CyoModal .nav-tabs {
  margin-top: 8px;
  overflow: auto hidden;
}

.CyoDetails .CyoDetailsTabs {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.CyoDetails .CyoDetailsTabs .tab-content {
  overflow: auto;
  flex: 1;
}

.CyoDetails .nav-tabs > li > a {
  padding: 0;
}

.CyoDetails .IngredientsSectionName .Name {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--color-text-primary);
}

.CyoModal .modal-control {
  position: absolute;
}

.CyoModal .modal-body {
  padding: 16px 0 0;
}

.CyoModal .modal-body > .content-top {
  width: 100%;
  text-align: left;
}

.CyoModal .modal-body > .content-top > h4 {
  color: #ec812d;
}

.CyoModal .NutritionalSection .content-top {
  margin: 0;
}

.CyoModal .modal-body #NutritionalTabs .tab-content {
  padding: 16px;
}

.CyoModal .modal-body .tab-content h5 {
  color: #ec812d;
}

.CyoDetails .nav-tabs {
  width: 100%;
  overflow: auto hidden;
}

.CyoModal .NutritionalSection > p {
  display: none;
}

.CyoSectionGroup .IngredientsSection {
  padding: 0;
  display: inline-block;
}

.CyoSectionGroup .IngredientsSectionHeader {
  padding: 0;
  text-align: center;
}

.CyoSectionGroup .IngredientsSectionName {
  float: initial;
}

.CyoDetails .CyoSectionGroup .IngredientsSection .Ingredient {
  background-color: var(--color-panel-primary);
  border: none;
}

.CyoModal .CyoSectionGroup .IngredientsSection .Ingredient {
  box-shadow: none;
}
.CyoModal .CyoSectionGroup .IngredientsSection .Ingredient .IngredientName p {
  font-size: 1em;
}

#NutritionalTabs .tab-content {
  text-align: left;
}

#NutritionalTabs .CyoSectionGroup {
  padding: 0 0 8px;
  margin-bottom: 8px;
}

#NutritionalTabs .IngredientsSectionHeader {
  padding: 0;
}

#NutritionalTabs .IngredientsSectionName,
.CyoDetails .IngredientsSectionName {
  float: initial;
  width: 100%;
}

#NutritionalTabs .IngredientsSectionName h5,
.CyoDetails .IngredientsSectionName h5 {
  font-size: 1em;
  margin: 0;
  font-weight: 400;
  width: 100%;
  text-align: center;
}

.NutritionalBlock .IngredientsSectionName h5,
#NutritionalTabs .IngredientsSectionName h5 {
  text-align: left;
  font-size: 1.16em;
}

.Cyo #NutritionalTabs > li {
  flex: 0;
}

.CyoSectionGroup {
  padding: 0px 0 8px;
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
}

.CyoModal .CyoSectionGroup {
  position: initial;
}

.CyoModal .IngredientsList,
.CyoDetails .IngredientsList {
  height: 100%;
}

.CyoModal.NutritionalModal.modal .modal-body {
  padding: 16px 0;
}

.CyoModal .CyoModalName {
  text-align: center;
}

.CyoModal .IngredientsSectionName .MuiTypography-root {
  font-size: 16px;
  color: var(--color-dark);
  font-weight: 400;
  font-family: 'Sharp Grotesk SmBold15';
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/*--- Tablet & Mobile---*/
@media (max-width: 992px) {
  .CyoModal .CyoSectionGroup .IngredientsSection h5 {
    font-size: 1em;
    font-family: 'Work Sans';
  }
}
