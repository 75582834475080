.PromoCodeInputWrapper {
  margin-top: 4px;
}

.PromoCodeInputWrapper .copy {
  text-align: left;
  margin-bottom: 8px;
}

.PromoCodeInputMain {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.PromoCodeInputMain > .Button.btn-primary {
  font-size: 0.9em;
  padding: 0px 24px;
  border-radius: 60px;
}

.PromoCodeInputMain .PromoCodeInput {
  position: relative;
  display: flex;
  margin-right: 15px;
  width: 50%;
}

.PromoCodeInputMain .PromoCodeInput .Input {
  position: relative;
  width: 100%;
  background-color: transparent;
  font-size: 18px;
  padding: 12px 20px !important;
  height: 48px;
}

.PromoCodeInputMain .PromoCodeInput .Input.noValue {
  padding-left: 20px !important;
}

/* Layering buttons and input */
.PromoCodeInputMain .PromoCodeInput .Input {
  z-index: 1;
}

.PromoCodeInputMain .PromoCodeInput .Input.hasError {
  background-color: #fbece9 !important;
}

.PromoCodeInputMain .PromoCodeInput .ClearButton {
  z-index: 2;
}

.PromoCodeInputMain .PromoCodeInput .PlaceholderIcon {
  z-index: 4;
}

.PromoCodeInputMain .PromoCodeInput .Input::placeholder {
  font-size: 14px;
  font-family: 'Work Sans';
}

.PromoCodeInputMain .PromoCodeInput .PlaceholderIcon {
  position: absolute;
  left: 10px;
  top: 16px;
  color: var(--color-text-primary);
}

.PromoCodeInputMain.hasError .PromoCodeInput .PlaceholderIcon {
  color: #d85a5b;
}

.PromoCodeInputMain .PromoCodeInput .ClearButton {
  position: absolute;
  right: 16px;
  top: 15px;
  color: var(--color-text-primary);
}

.PromoCodeInputMain.hasError .PromoCodeInput .ClearButton {
  color: var(--color-text-error);
}

.PromoCodeInputMain > .Button {
  padding: 0 12px;
  height: 48px;
}

.PromoCodeInputMain .DbsLogoWrapper {
  padding-left: 15px;
  height: 48px;
  position: relative;
}

.PromoCodeInputMain .DbsLogoWrapper .DbsLogo {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.PromoCodeInputWrapper .PromoCodeError {
  margin-top: 4px;
  color: var(--color-text-error);
  text-align: left;
  padding-left: 8px;
  font-size: 14px;
}

.PromoCodeInputWrapper .PromoCodeError .lnr {
  margin-right: 5px;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .PromoCodeInputMain {
    margin-top: 8px;
  }

  .PromoCodeInputMain .PromoCodeInput {
    margin-right: 0;
  }

  .PromoCodeInputMain .PromoCodeInput .Input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .PromoCodeInputMain > .Button.btn-primary {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
