.payment-icon {
  margin-bottom: 18px;
}

.Payment .Disclaimer {
  font-size: 0.9em;
  margin-bottom: 30px;
}

.Payment .fa {
  color: var(--color-text-primary);
}
