.Account .PastOrder.panel {
  border: none;
  border-radius: 16px;
  overflow: hidden;
}

.PastOrderHeader {
  width: 100%;
  display: flex;
}

.PastOrderHeader .Body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.PastOrderHeader .Details {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.PastOrderHeader .Body .Date {
  font-size: 0.8em;
  margin-bottom: 0px;
  line-height: 18px;
}

.PastOrderHeader .Body .GglocationName {
  color: var(--color-text-grey);
}

.PastOrderHeader .Body .GglocationName .OrderNumber {
  color: #d6bca3;
}

.PastOrderHeader .Price {
  font-size: 1em;
  color: var(--color-text-grey);
  margin-top: 2px;
}

.PastOrderHeader .Price .DollarSign {
  font-size: 0.8em;
  margin-right: 4px;
}

.PastOrderHeader .Price .fa {
  margin-left: 8px;
  vertical-align: text-bottom;
  color: var(--color-primary);
}

.PastOrderHeader .Price a {
  display: initial;
}

.PastOrderHeader .Status {
  flex: 1;
  font-size: 14px;
  color: var(--color-text-contrast);
  display: none;
}

.PastOrderHeader .Status span {
  padding: 2px 5px;
  border-radius: 3px;
}

.PastOrderHeader .Status .Pending {
  background-color: var(--color-light);
}

.PastOrderHeader .Status .Cancelled {
  background-color: #8ca1a5;
}

.PastOrderHeader .Status .Ready {
  background-color: var(--color-primary);
}

.PastOrder .PastOrderItems {
  font-size: 0.95em;
}

.PastOrderFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
}

.PastOrderFooter .Button.btn-primary {
  padding: 8px 24px;
  float: right;
}

.PastOrderHeader .DiningChoice {
  font-weight: 600;
  text-transform: uppercase;
}

.PastOrderHeader .OrderNumber {
  font-weight: bold;
  text-transform: uppercase;
}
