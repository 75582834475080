.Favorites {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.Favorites .ButtonsRow {
  display: flex;
  width: 75%;
}

.Favorites .Button {
  height: 50px;
}

.Favorites .ButtonsRow .btn-primary {
  flex: 3;
}

.Favorites .ButtonsRow .Button.blue-link {
  flex: 2;
}

.Favorites .blue-link {
  text-decoration: none;
}

.FavoriteMenuListWrapper {
  margin-bottom: 8px;
  overflow-y: auto;
}

.FavoriteMenuItemsList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  overflow-y: auto;
}

.FavoriteMenuItemsList > li {
  flex: 0 0 75%;
  max-width: 480px;
}

.FavoriteMenuItemsList .FavoriteMenuItem {
  height: calc(100% - 14px);
}

.OrderHistoryRow {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.EmptyFavorites {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 0px;
}

.EmptyFavorites .blue-link {
  margin-left: 5px;
  white-space: nowrap;
}

.EmptyFavorites .blue-link span {
  font-size: 1.2em;
}

.EmptyFavorites .Button {
  width: 100%;
}

.EmptyFavorites p {
  margin: auto;
}

.EmptyFavorites svg {
  margin-bottom: 16px;
}

.EmptyFavorites .ButtonsRow .FavoritesLine {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.EmptyFavorites .ButtonsRow .FavoritesLine .Button {
  margin: 0 4px;
  flex: 0;
}

.Favorites .or {
  font-style: italic;
  margin: 0px auto 16px;
}

.Favorites .ButtonsRow .blue-link {
  padding: 0;
}

.Favorites .ButtonsRow .btn-primary {
  margin-left: 24px;
  max-width: 256px;
  padding: 16px 60px;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .FavoriteMenuItemsList > li {
    flex: 0 0 100%;
    max-width: unset;
  }

  .Favorites .ButtonsRow {
    flex-direction: column;
    width: 100%;
    text-align: center;
    align-items: center;
  }

  .Favorites .ButtonsRow .Button {
    margin: 0px;
  }

  .Favorites .ButtonsRow .Button {
    flex: 0;
  }

  .Favorites .ButtonsRow .Button.btn-primary {
    padding: 16px 48px;
  }

  .FavoriteMenuItemsList .FavoriteMenuItem {
    width: 100%;
  }

  .EmptyFavorites {
    max-width: unset;
    align-items: center;
  }

  .EmptyFavorites .Button {
    width: unset;
  }
}
