.IngredientCategories {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
}

.IngredientCategories > div {
  flex: 1 1 0;
}

.IngredientCategories > div + div {
  margin-top: 16px;
}

.IngredientCategoryTitle {
  padding-left: 24px;
  padding-right: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.IngredientCategoryTitle .DietTag {
  margin: 4px;
}

.IngredientCategoryTitle .DietTag:first-of-type {
  margin-left: auto;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .IngredientCategoryTitle {
    text-align: left;
  }

  .IngredientCategories > div:first-of-type {
    margin-top: 8px;
  }
}
