/* img-store */
.PickupTimeSelector {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background-color: var(--color-secondary);
}

.PickupTimeSelector .ImageStore {
  display: flex;
  flex: 2;
  flex-direction: column;
  overflow: hidden;
}

.PickupTimeSelector .ImageStore > img {
  display: flex;
  flex: 1;
  max-height: 100%;
  object-fit: cover;
}

/* .content-container */
.PickupTimeSelector .ContentContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  align-items: center;
  padding-top: 35px;
}

.PickupTimeSelector .ContentContainer .title {
  margin-bottom: 8px;
}

.PickupTimeSelector .ContentContainer .ActionButton > .Button {
  position: initial;
}

.PickupTimeSelector .ContentContainer .GglocationInfo {
  padding: 0 30px;
  width: 80%;
  text-align: center;
}

.PickupTimeSelector .leaflet-tooltip-right {
  padding: 0;
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.PickupTimeSelector .leaflet-tooltip-right > div {
  background-color: var(--color-background);
  color: var(--color-text-grey);
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  min-height: 56px;
  min-width: 120px;
  display: flex;
  align-items: center;
  font-size: 16px;
  border-radius: 16px;
  padding: 16px;
  margin-left: 32px;
}

.PickupTimeSelector .leaflet-tooltip-right::before {
  display: none;
}

/* Tablet and Mobile */
@media (max-width: 992px) {
  /* .content-container */
  .PickupTimeSelector {
    flex-direction: column;
  }

  .PickupTimeSelector ul.picker-time li {
    display: inline-flex;
    width: 47.3%;
    height: 52px;
    margin: 8px 1%;
  }

  /* #img-store */
  .PickupTimeSelector .ImageStore {
    border-right: none;
    position: relative;
    max-height: 22vh;
    display: none;
  }

  .PickupTimeSelector .ContentContainer {
    padding-top: 32px;
  }

  .PickupTimeSelector .ContentContainer .GglocationInfo {
    width: 100%;
    padding: 0 32px;
  }

  .PickupTimeSelector .shop-info p.shop-add {
    margin: 10px auto 12px auto;
    width: 100%;
    position: relative;
    font-size: 0.95em;
  }

  .PickupTimeSelector .Image-original {
    display: none;
  }
}
