/* .store-container */
.GglocationLocator {
  display: flex;
  overflow: auto;
  flex: 1 1 auto;
  background-color: var(--color-secondary);
}

.StoreContainer {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.StoreContainer .Disclaimer {
  padding: 5px 10px 10px;
  text-align: center;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  /* .store-container */
  .StoreContainer .Disclaimer {
    padding: 8px 10px 0;
  }

  .StoreContainer {
    padding-bottom: 48px;
  }
}
