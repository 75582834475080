.OrderDataValidator.modal .modal-body {
  padding-bottom: 35px;
}

.OrderDataValidator .ButtonsRow {
  padding: 0 10px;
  justify-content: center;
}

@media (min-width: 768px) {
  .OrderDataValidator .ButtonsRow .Button {
    max-width: 280px;
  }
}
