.Addresses {
  text-align: center;
  overflow: auto;
}

.Addresses .header {
  font-size: 14px;
  font-weight: 600;
}
.Addresses .AddItemButton {
  width: 600px;
  float: left;
}

.Addresses .AddressesList {
  margin-bottom: 8px;
  flex-wrap: wrap;
  overflow: auto;
}

.Addresses .AddressesList.selectable {
  margin-bottom: unset;
}

.Addresses .AddressItem {
  flex-wrap: wrap;
  flex-direction: row;
  text-decoration: none;
  font-size: 16px;
  color: black;
}

.Addresses .MoreAddresses {
  color: #b1a59a;
  border: 1px solid #c1b9b1;
  padding: 10px;
  width: 100%;
  border-radius: 16px;
}

.Addresses .SectionSeparator {
  margin-bottom: 4px;
}

.Addresses .SectionSeparator.header .name .MuiTypography-root {
  font-weight: 700;
}

.Account .Addresses .title-function {
  text-align: left;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .Addresses {
    padding-bottom: 24px;
  }

  .AddressesList > li {
    flex: 0 0 100%;
  }

  .AddressesList .FavoriteMenuItem {
    width: 100%;
  }

  .Addresses .AddItemButton {
    width: 100%;
  }
}
