.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
}
@keyframes slideUp {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
  }
}
@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(30%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}

.slideInUp {
  animation-name: slideInUp;
  -webkit-animation-name: slideInUp;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  opacity: 1;
}
@keyframes slideInUp {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(30%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
    opacity: 1;
  }
}

.slideOutUp {
  animation-name: slideOutUp;
  -webkit-animation-name: slideOutUp;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  opacity: 0;
}
@keyframes slideOutUp {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(-30%);
    opacity: 0;
    visibility: hidden !important;
  }
}
@-webkit-keyframes slideOutUp {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(-30%);
    opacity: 0;
    visibility: hidden !important;
  }
}
