.Pickup {
  padding: 24px 24px 20px 24px;
}

.Pickup .GglocationsSelect {
  max-width: unset;
  width: 100%;
  margin: 0;
  text-align-last: center;
  font-family: 'Work Sans';
}

.Pickup .GglocationsSelect .Select {
  border-radius: 16px;
}

.Pickup .Button.btn-primary {
  height: 55px;
  width: 100%;
}

.Pickup .groupOrderingUrlLabel .Currency {
  display: inline;
}

/* Mobile */
@media (max-width: 767px) {
  .Pickup .groupOrderingUrlLabel {
    padding-top: 6px;
  }
}
