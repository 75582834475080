.NutritionalModal {
  display: none;
}

.NutritionalModal.modal .modal-body {
  padding: 0;
}

.NutritionalModal.modal .modal-body .content-top {
  width: 100%;
  padding: 32px 32px 24px 24px;
  text-align: center;
}

.NutritionalModal .content-top {
  text-align: left;
  padding: 0 20px;
}

.NutritionalModal .nav-tabs {
  background-color: transparent;
}

.NutritionalModal .nav-tabs > li > a {
  width: 100%;
  font-weight: bold;
}

.NutritionalModal #NutritionalTabs .tab-content {
  padding: 16px;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .NutritionalModal {
    display: table;
  }
}

/* Mobile Portrait Only*/
@media (max-width: 479px) {
  .modal .modal-body .content-top {
    margin-top: -60px;
  }
}
