ul.GiftCardCategory {
  margin-top: 0px;
  display: inline-flex;
  flex-wrap: wrap;
  width: inherit;
  justify-content: flex-start;
  width: 100%;
}

.category-desc {
  margin-bottom: 0px;
  margin-left: 4px;
}

.category-desc p {
  font-size: 0.95em;
  width: 70%;
  line-height: 1.6em;
  margin: 0;
  text-align: left;
}

/*--- Mobile Landscape & Portrait---*/
@media (max-width: 767px) {
  .category-desc {
    display: none;
  }
  ul.GiftCardCategory.scrollable {
    flex-wrap: nowrap;
    margin: 0 auto;
    overflow-x: scroll;
    flex-direction: row;
    justify-content: initial;
  }
  ul.GiftCardCategory.scrollable::-webkit-scrollbar {
    display: none;
  }
  ul.GiftCardCategory.scrollable .MenuItem {
    flex-shrink: 0;
  }
}

.absolute-right-top {
  position: absolute;
  z-index: 99;
  top: 10px;
  right: 10px;
}
