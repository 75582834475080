.Cart .CartBody .CartCheckout .TimePicker {
  display: flex;
}

.Cart .CartBody .CartCheckout .TimePicker .SelectWrapper {
  flex: 1;
}

.Cart .CartBody .CartCheckout .TimePicker .Select {
  appearance: none;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid var(--color-primary) !important;
  border-radius: 60px;
  box-shadow: none;
  color: var(--color-text-primary);
  text-align: left;
  width: fit-content;
}
.Cart .CartBody .CartCheckout .TimePicker #TimePickerHours {
  margin-right: 5px;
}
.Cart .CartBody .CartCheckout .TimePicker #TimePickerMinutes {
  margin-left: 10px;
}
