.OrderItemPriceDelta {
  display: inline-block;
}

.OrderItemPriceDelta .OriginalPrice {
  display: inline-flex;
}

.OrderItemPriceDelta.isDiscounted {
  display: flex;
}

.OrderItemPriceDelta.isDiscounted .OriginalPrice {
  text-decoration: line-through;
  margin-right: 10px;
  font-size: 0.9em;
  opacity: 0.7;
}
