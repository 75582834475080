.PastScan {
  width: 100%;
  border-radius: 16px;
  border: none;
  background-color: var(--color-secondary);
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.PastScan .ScanDate {
  margin-right: 8px;
}

.PastScan .PastScanHeader {
  display: flex;
  justify-content: space-between;
}

.PastScan .HeaderLeft {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.PastScan .RefNumber {
  font-weight: bold;
}
