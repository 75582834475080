.AddItemButton {
  margin-top: 16px;
  display: inline-block;
  border: 1px solid var(--color-primary);
  background-color: none;
  border-radius: 48px;
  width: 53%;
  color: var(--color-primary);
  height: 48px;
  min-height: 48px;
}

.AddItemButton:hover {
  border-width: 2px;
}

.AddItemButton svg {
  width: 16px;
}

@media (max-width: 992px) {
  .AddItemButton {
    width: 100%;
  }
}
