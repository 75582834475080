.TNCSection {
  width: 100%;
  box-shadow: none !important;
  padding: 24px 40px;
  text-align: left;
}

.MuiAccordion-root.TNCSection:before {
  content: none;
}

.TNCSection .Title {
  padding: 0;
  margin-bottom: 4px;
}

.TNCSection .TNCDetails {
  max-height: 250px;
  overflow-y: auto;
  white-space: pre-wrap;
  padding: 0;
  margin-bottom: 16px;
}

.MuiAccordion-root.TNCSection {
  padding: 0 40px;
  background-color: var(--color-modal-primary);
}

.TNCSection .MuiAccordionSummary-expandIconWrapper {
  color: #7a7a7a;
}

/* Tablet and mobile */
@media (max-width: 767px) {
  .MuiAccordion-root.TNCSection {
    padding: 0 16px 8px;
  }
}
