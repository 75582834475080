.ButtonsRow,
.ButtonsRow > div,
.ButtonsRow > span {
  display: flex;
  flex: 1;
}

.ButtonsRow .Button {
  flex: 1;
  padding: 15px;
  margin: 0;
}

.ButtonsRow .Button + .Button {
  margin-left: 16px;
}

/*--- Mobile Landscape and Portrait ---*/
@media (max-width: 767px) {
  .ButtonsRow {
    flex-direction: column-reverse;
  }

  .ButtonsRow .Button + .Button {
    margin-left: 0px;
    margin-bottom: 8px;
  }
}
